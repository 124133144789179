import VaultTextBase from "./VaultTextBase";
import * as PIXI from 'pixi.js';
import Constants from "../../../shared/constants";
import Penner from "../../../vendor/hexademic/Penner";

export default class VaultTextHeadline extends VaultTextBase {

  constructor(data) {
    super(data);
    // setup animation
    this.textWhite = this.buildTextSprite('#ffffff');
    this.textBlack = this.buildTextSprite('#000000');
  }

  ////////////////////
  // BUILD TEXT
  ////////////////////

  buildTextSprite(textColor, bold=false) {
    // set responsive width for text style
    let wordWrapWidth = (this.animData.isPortrait()) ?
      (900/1080) * this.stage.width() :
      (1600/1920) * this.stage.width();
    let textSize = (this.animData.isPortrait()) ?
      (218/1920) * this.stage.height() :
      (175/1080) * this.stage.height();
    let lineSpacing = (this.animData.isPortrait()) ?
      textSize * 0.9 :
      textSize * 1.0;

    // let fontFam = (bold) ? 'HelveticaBold' : 'Helvetica';
    let fontFam = 'NeueHelveticaHOI';
    let textStyle = new PIXI.TextStyle({
      fontFamily: fontFam,
      fontSize: textSize * Constants.TEXT_RESOLUTION,
      lineHeight: lineSpacing * Constants.TEXT_RESOLUTION,
      letterSpacing: this.stage.width() * -0.005,
      fill: textColor,
      wordWrap: true,
      wordWrapWidth: wordWrapWidth * Constants.TEXT_RESOLUTION,
      // padding: textSize * Constants.TEXT_RESOLUTION * 0.1, // Old Padding
      padding: textSize * Constants.TEXT_RESOLUTION * 0.15 // increasing padding due to text clipping
    });
    if(this.animData.isPortrait()) {
      textStyle.align = 'right';
    }

    // build text sprite
    let sprite = new PIXI.Text(this.animData.textBigHeadline.toUpperCase(), textStyle);
    sprite.anchor.set(0, 0);
    sprite.scale.set(1 / Constants.TEXT_RESOLUTION); // scale down from retina-style 2x scaling

    // position
    if(this.animData.isPortrait()) {
      sprite.anchor.set(1, 0);  // anchor top-right
      // sprite.x = 1.02 * this.stage.width(); // old position
      sprite.x = (1030/1080) * this.stage.width() // set to same distance from right as from left in portrait mode
      sprite.y = (425/1920) * this.stage.height();
      // sprite.y = (520/1920) * this.stage.height();   // old position, further down
    } else {
      // old y position
      sprite.x = (50/1920) * this.stage.width();
      sprite.y = (140/1080) * this.stage.height();
      // sprite.y = (230/1080) * this.stage.height();   // old position, further down
    }

    // add to stage
    this.container.addChild(sprite);
    return sprite;
  }

  ////////////////////
  // ANIMATE
  ////////////////////

  ANIMATION_FRAME(frame) {
    super.ANIMATION_FRAME(frame);

    let easedShow = Penner.easeInOutExpo(this.activeShowEase.value(), 0, 1, 1);
    this.textWhite.alpha = 1. - easedShow;
    this.textBlack.alpha = easedShow;
  }

}
