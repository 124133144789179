import PixiSpriteScale from "../../../vendor/hexademic/PixiSpriteScale";
import Penner from "../../../vendor/hexademic/Penner";

export default class VaultVideo {

  constructor(data, slideData) {
    this.data = data;
    this.slideData = slideData;

    // event listeners
    _store.addListener(this, Constants.ANIMATION_FRAME);
    _store.addListener(this, Constants.ACTIVE_MODE);

    // init container
    this.stage = _store.get(Constants.PIXI_STAGE);
    this.container = new PIXI.Container();
    _store.get(Constants.APP_CONTAINER).addChild(this.container);

    // setup animation
    this.buildAnimProps();
    this.loadMedia();
  }

  buildAnimProps() {
    this.activeShowEase = new LinearFloat(0, 0.025);
    this.autoHide = false;
    this.didGoFullscreen = false;
  }

  ////////////////////
  // LOAD MEDIA
  ////////////////////

  loadMedia() {
    this.loaded = false;
    this.scaledAfterLoad = false;
    this.videoEl = document.createElement('video'); // dummy video element until the real thing is loaded
    if (!this.slideData.isIpad) this.slideData.assetUrl = this.slideData.assetUrl.replace('.mp4', '.webm');

    // load video via xhr ---------------------------
    new VideoToBlob(this.slideData.assetUrl, (videoEl) => {
      // store video element w/blob src
      this.videoEl = videoEl;
      this.videoEl.setAttribute('autoplay', "true");

      // create pixi texture
      var texture = PIXI.Texture.from(this.videoEl);
      texture.baseTexture.resource.updateFPS = 30;
      this.sprite = new PIXI.Sprite(texture);
      this.container.addChild(this.sprite);
    });

    // document.body.appendChild(this.videoEl);  // show video in DOM for debugging
    // this.videoEl.play(); // if play is called immediately, it kills the ability to display (?!)
  }

  ////////////////////
  // MEDIA LOADED CHECK
  ////////////////////

  isMediaLoaded() {
    let loaded = this.slideData.subType == "video" && this.sprite && this.sprite.texture && this.sprite.width > 10;
    return loaded;
  }

  resizeOnLoad() {
    if(this.scaledAfterLoad == false && this.isMediaLoaded()) {
      this.resizeForLayout();
      this.scaledAfterLoad = true;
    }
  }

  // two layouts - in-design or fullscreen

  resizeForLayout() {
    if(this.isMediaLoaded() == false) return;
    // conditional sizing
    let width = (this.data.isPortrait()) ?
      (1020/1080) * this.stage.width() :
      (1342/1920) * this.stage.width();
    let x = (this.data.isPortrait()) ?
      0 * this.stage.height() :
      (522/1920) * this.stage.width();
    let y = (this.data.isPortrait()) ?
      (790/1920) * this.stage.height() :
      (276/1080) * this.stage.height();
    PixiSpriteScale.scaleToWidth(this.sprite, width);
    this.sprite.x = x;
    this.sprite.y = y;
    this.sprite.anchor.set(0, 0);  // anchor top-right
    this.didGoFullscreen = false;
  }

  goFullScreen() {
    PixiSpriteScale.scaleToFillContainerMult(this.sprite, this.stage.width(), this.stage.height(), 1.02);
    this.sprite.x = this.stage.width() / 2;
    this.sprite.y = this.stage.height() / 2;
    this.sprite.anchor.set(0.5, 0.5);
    this.didGoFullscreen = true;
  }

  ////////////////////
  // SHOW/HIDE
  ////////////////////

  ACTIVE_MODE(isActive) {
    if(isActive) {
      this.show();
    } else {
      this.hide();
    }
  }

  show() {
    this.activeShowEase.setTarget(1);
    this.resizeForLayout();
    this.fullscreenProgressStart = 0.3 + 0.2 * Math.random();
    this.fullscreenProgressStop  = 0.6 + 0.2 * Math.random();
    this.autoHide = false;  // help shut down active mode when video ends
    this.videoEl.currentTime = 0;
    this.videoEl.play();
    this.videoEl.volume = 0;
    this.videoEl.muted = true;
    // this.videoEl.playbackRate = 5;
  }

  hide() {
    this.activeShowEase.setTarget(0);
  }

  ////////////////////
  // ANIMATE
  ////////////////////

  checkVideoComplete() {
    if(this.videoEl.duration - this.videoEl.currentTime < 1) {
      if(this.autoHide == false) {
        this.autoHide = true;
        app.setActive(false);
      }
    }
  }

  doFullscreenLayoutToggle() {
    if(this.data.isPortrait() == false) {
      if(this.activeShowEase.value() > 0) {
        let progress = this.videoEl.currentTime / this.videoEl.duration;
        if(progress > this.fullscreenProgressStart && progress < this.fullscreenProgressStop && this.didGoFullscreen == false) {
          this.goFullScreen();
        } else if(progress > this.fullscreenProgressStop && this.didGoFullscreen == true) {
          this.resizeForLayout();
        }
      }
    }
  }

  ANIMATION_FRAME(frame) {
    this.resizeOnLoad();
    this.activeShowEase.update();

    // this.checkVideoComplete();
    this.doFullscreenLayoutToggle();

    // alpha & show/hide
    if(this.isMediaLoaded()) {
      let easedShow = Penner.easeInOutExpo(this.activeShowEase.value(), 0, 1, 1);
      this.sprite.alpha = easedShow;
      if(this.activeShowEase.value() == 0) this.sprite.visible = false;
      else this.sprite.visible = true;
    }
  }


}
