import AppEventsEnum from '../enums/AppEvents.enum';
import TemplateEventsEnum from '../enums/TemplateEvents.enum';
import DataEventsEnum from '../enums/DataEvents.enum';
import PixelMapEventsEnum from '../enums/PixelMapEvents.enum';

declare global {
	interface Window {
		_processId: string;
	}
}

declare type EventType = AppEventsEnum | TemplateEventsEnum | DataEventsEnum | PixelMapEventsEnum;

export function sendEvent(event: EventType, payload: any = null): void {
	if (!window?.top?.postMessage || typeof window?.top?.postMessage !== 'function') {
		console.error(`Unable to trigger "${event}" event: no message service provided`);
		return;
	}

	if (typeof event !== 'string') {
		console.error(`Invalid event type: string expected, "${typeof event}" received`);
		return;
	}

	window.top.postMessage({
		event,
		processId: window._processId ?? null,
		payload,
	}, '*');
}
