class LoadingStatus {

	static set(status) {
		if (LoadingStatus.el) LoadingStatus.el.innerHTML = status;
		if (status == LoadingStatus.COMPLETE) LoadingStatus.complete();
		if (!!window._store) window._store.set(LoadingStatus.STATUS, status);
	}

	static complete() {
		// clear out debug elements
		setTimeout(function () {
			if (LoadingStatus.el) LoadingStatus.el.parentNode.removeChild(LoadingStatus.el);
		}, 3000);

		let htmlIndicator = document.getElementById('html-indicator');

		if (htmlIndicator) {
			htmlIndicator.parentNode.removeChild(htmlIndicator);
		}
	}
}

// loading
LoadingStatus.el = document.getElementById("loading-status");
LoadingStatus.STATUS = "LoadingStatus.STATUS";
LoadingStatus.BOOTSTRAP = "Step 1: BOOTSTRAPPING";
LoadingStatus.REQUEST_DATA = "Step 2: LOADING DATA";
LoadingStatus.MAIN_CLASS_INIT = "Step 3: MAIN CLASS INIT";
LoadingStatus.LOAD_FONTS = "Step 4: LOADING FONTS";
LoadingStatus.INIT_OBJECTS = "Step 5: MAIN CLASS INIT";
LoadingStatus.INIT_STAGE = "Step 6: STAGE INIT";
LoadingStatus.INIT_PIXI = "Step 7: PIXI INIT";
LoadingStatus.ANIMATION_STYLE = "Step 8: ANIMATION STYLE INIT";
LoadingStatus.SLIDES_BUILT = "Step 9: SLIDES BUILT";
LoadingStatus.GLITCH_BUILT = "Step 8: GLITCH BUILT";
LoadingStatus.JSON_INIT_FAILED = "JSON INIT FAILED";
LoadingStatus.NO_SLIDES = "NO SLIDES";
LoadingStatus.COMPLETE = "LOADING_COMPLETE";
LoadingStatus.NO_ANIM_DATA = "NO_ANIM_DATA";

export default LoadingStatus;
