import SlideBase from "./SlideBase";
import Constants from "../../../shared/constants";
import LinearFloat from "../../../vendor/hexademic/LinearFloat";
import * as PIXI from 'pixi.js';
import Penner from "../../../vendor/hexademic/Penner";
import LoadingStatus from "../../../shared/LoadingStatus";
import { getCheer } from "../../../../../api";

export default class SlideTitle extends SlideBase {

  constructor(slidesData) {
    super();
    this.slidesData = slidesData;
    // console.log('this.slidesData', this.slidesData);
    this.slideTime = Constants.SLIDE_TIME_TITLE;
    this.likesCount = 0;
    this.buildAnimProps();
    this.buildMask();
    this.buildBackground();
    this.buildTextLayers();
    this.checkShouts();
    _store.set(Constants.SLIDES_LOADED, _store.get(Constants.SLIDES_LOADED) + 1);
  }

  ////////////////////
  // DATA HELPERS
  ////////////////////

  isPrimaryColor() {
    // console.log(this.slidesData.primaryColor)
    return true; //  this.slidesData.category == Constants.CATEGORY_TV;  // slides up on TV, so we'll pretend it's a primary color slide. otherwise, iPad slides left
  }

  ////////////////////
  // INIT MASK & ANIM PROPS
  ////////////////////

  buildMask() {
    this.maskGraphics = new PIXI.Graphics();
    this.maskGraphics.beginFill(0xFF700B, 1);
    this.maskGraphics.drawRect(0, 0, this.stage.width(), this.stage.height());
    // this.container.addChild(this.maskGraphics);
    _store.get(Constants.APP_CONTAINER).addChild(this.maskGraphics);
    this.container.mask = this.maskGraphics;
  }

  buildBackground() {
    this.background = new PIXI.Graphics();
    this.background.beginFill(0xF8F8F8, 1);
    this.background.drawRect(0, 0, this.stage.width(), this.stage.height());
    this.container.addChild(this.background);
  }

  buildAnimProps() {
    this.maskShowEase = new LinearFloat(1, 0.015);
    this.headlineShowEase = new LinearFloat(1, 0.005);
    this.headlineHideEase = new LinearFloat(0, 0.015);
    this.descriptionShowEase = new LinearFloat(1, 0.00425);
    this.descriptionHideEase = new LinearFloat(0, 0.015);
    this.productNameShowEase = new LinearFloat(1, 0.0035);
    this.productNameHideEase = new LinearFloat(0, 0.015);
  }

  buildTextLayers() {
    // shared positions
    this.textBaseX = this.stage.width() * 0.1;
    let headingPaddingX = (this.slidesData.isPortrait()) ? 0.1 : 0.07;

    // headline
    let headlineResolution = (this.slidesData.isPortrait()) ? 2 : 1.5;
    this.headlineText = new PIXI.Text(this.slidesData.textHeadline, this.getTextStyleHeadline(headingPaddingX * 2, headlineResolution));
    this.headlineY = this.stage.height() * 0.205;
    this.container.addChild(this.headlineText);
    this.headlineText.scale.set(1 / headlineResolution); // Constants.TEXT_RESOLUTION);

    // sub-headline
    if(this.slidesData.textSubHeadline && this.slidesData.textSubHeadline.length > 0) {
      this.descriptionText = new PIXI.Text(this.slidesData.textSubHeadline, this.getTextStyleBody());
      this.container.addChild(this.descriptionText);
      this.descriptionText.scale.set(1 / Constants.TEXT_RESOLUTION);
    }

    // product name
    this.productNameText = new PIXI.Text(this.slidesData.textProductName, this.getTextStyleSmallBold());
    this.container.addChild(this.productNameText);
    this.productNameText.scale.set(1 / Constants.TEXT_RESOLUTION);
    this.productNameY = this.stage.height() * 0.72;

    // heart
    let heartTextSizeMult = 0.028; // (this.slidesData.isPortrait()) ? 0.028 : 0.028;
    let heartTextYMult = (this.slidesData.isPortrait()) ? 0.7592 : 0.758;
    this.heartText = new PIXI.Text('♥', this.getTextStyleIcon(heartTextSizeMult));
    this.container.addChild(this.heartText);
    this.heartText.scale.set(1 / Constants.TEXT_RESOLUTION);
    this.heartY = this.stage.height() * heartTextYMult;

    // likes count
    this.likesText = new PIXI.Text('    ' + this.likesCount, this.getTextStyleSmallBold(false));
    this.container.addChild(this.likesText);
    this.likesText.scale.set(1 / Constants.TEXT_RESOLUTION);
    this.likesY = this.stage.height() * 0.763;

    // bump bottom row for ipad
    if (this.slidesData.isPortrait() == false) {
      this.textBaseX = this.stage.width() * headingPaddingX;
      this.headlineY = this.stage.height() * 0.175;
      this.heartY += this.stage.height() * 0.03;
      this.likesY += this.stage.height() * 0.03;
    }
  }

  ////////////////////
  // SHOW/HIDE
  ////////////////////

  show() {
    super.show();
    this.maskShowEase.setValue(1);
    this.maskShowEase.setTarget(0);

    this.headlineShowEase.setValue(1);
    this.headlineShowEase.setTarget(0);
    this.headlineHideEase.setValue(0);
    this.headlineHideEase.setTarget(0);

    this.descriptionShowEase.setValue(1);
    this.descriptionShowEase.setTarget(0);
    this.descriptionHideEase.setValue(0);
    this.descriptionHideEase.setTarget(0);

    this.productNameShowEase.setValue(1);
    this.productNameShowEase.setTarget(0);
    this.productNameHideEase.setValue(0);
    this.productNameHideEase.setTarget(0);
  }

  hide() {
    let wasShowing = this.isShowing;
    super.hide();
    this.headlineHideEase.setTarget(1);
    this.descriptionHideEase.setTarget(1);
    this.productNameHideEase.setTarget(1);
    this.checkShouts();
    if(!this.LoadingCompleteShown) {
      this.LoadingCompleteShown = true;
      LoadingStatus.set(LoadingStatus.COMPLETE);
    }
    // render demo
    if(wasShowing) this.checkRender();
  }

  ////////////////////
  // RENDER
  ////////////////////

  checkRender() {
    if(!window.exportVideo) return;
    // finish when a full anim loop has been made after exporter is created
    if(this.exporter && !this.exporter.isFinished()) {
      this.exporter.finish();
    }

    // start
    if(this.exporter == null) {
      this.exporter = new VideoExporter(this.stage.app.view, {
        callback: window.exportVideoCallback
      });
      this.exporter.start();
    }
  }

  checkRecordFrame() {
    if(this.exporter) this.exporter.addFrame();
  }

  ////////////////////
  // ANIMATE
  ////////////////////

  ANIMATION_FRAME(frame) {
    this.animateLayers();
    this.checkRecordFrame();
  }

  animateLayers() {
    this.maskShowEase.update();
    this.headlineShowEase.update();
    this.headlineHideEase.update();
    this.descriptionShowEase.update();
    this.descriptionHideEase.update();
    this.productNameShowEase.update();
    this.productNameHideEase.update();

    // move image & mask layers
    // switch axis based on primary/secondary color
    let isTV = this.slidesData.isPortrait();
    let axis = (isTV) ? 'y' : 'x';
    let dimension = (isTV) ? this.stage.height() : this.stage.width();

    // mask position
    let maskPos = this.maskShowEase.valuePenner(Penner.easeInOutExpo) * dimension;
    this.maskGraphics[axis] = maskPos;

    // text positions
    let headlineOffset = this.headlineShowEase.valuePenner(Penner.easeInExpo) * dimension * 0.25;
    headlineOffset -= this.headlineHideEase.valuePenner(Penner.easeInOutExpo) * dimension * 0.17;
    let descriptionOffset = this.descriptionShowEase.valuePenner(Penner.easeInExpo) * dimension * 0.25;
    descriptionOffset -= this.descriptionHideEase.valuePenner(Penner.easeInOutExpo) * dimension * 0.16;
    this.descriptionY = this.headlineY + this.headlineText.height + dimension * 0.02; // this changes from the first frame of initialization, so lets check every frame
    let productNameOffset = this.productNameShowEase.valuePenner(Penner.easeInExpo) * dimension * 0.25;
    productNameOffset -= this.productNameHideEase.valuePenner(Penner.easeInOutExpo) * dimension * 0.16;

    // set likes below product name to position likes heart & count text
    let likesY = this.productNameText.position.y + this.productNameText.height + this.stage.height() * 0.02;
    this.likesY = likesY;
    let heartTextYMult = (this.slidesData.isPortrait()) ? 0.00345 : 0.00445;
    this.heartY = this.likesY - this.stage.height() * heartTextYMult;

    // set headline text position
    if (isTV) {
      this.headlineText.x = this.textBaseX;
      this.headlineText.y = this.headlineY + headlineOffset;
      if(this.descriptionText) {
        this.descriptionText.x = this.textBaseX;
        this.descriptionText.y = this.descriptionY + descriptionOffset;
      }
      this.productNameText.x = this.textBaseX;
      this.productNameText.y = this.productNameY + productNameOffset;
      this.heartText.x = this.textBaseX;
      this.heartText.y = this.heartY + productNameOffset;
      this.likesText.x = this.textBaseX;
      this.likesText.y = this.likesY + productNameOffset;
    } else {
      this.headlineText.x = this.textBaseX + headlineOffset;
      this.headlineText.y = this.headlineY;
      if(this.descriptionText) {
        this.descriptionText.x = this.textBaseX + descriptionOffset;
        this.descriptionText.y = this.descriptionY;
      }
      this.productNameText.x = this.textBaseX + productNameOffset;
      this.productNameText.y = this.productNameY;
      this.heartText.x = this.textBaseX + productNameOffset;
      this.heartText.y = this.heartY;
      this.likesText.x = this.textBaseX + productNameOffset;
      this.likesText.y = this.likesY;
    }
    // if (this.headlineText) this.headlineText[axis] = dimension + headlineOffset;
  }

  ////////////////////
  // PING LIKES ENDPOINT
  ////////////////////

  checkShouts() {
    // get product style id
    let slidesData = _store.get(Constants.DATA_PARSED);
    let productStyleId = slidesData.styleCode;

    // call the likes api and reset the text if the number has changed
    if(Constants.API_URL == null) return;

    getCheer({
	  baseURL: Constants.API_URL,
	  productStyleId
	})
    .then((jsonData) => {
      if(jsonData.total) {
        if(jsonData.total != this.likesCount) {
          this.likesCount = jsonData.total;
          this.likesText.text = '    ' + this.formatLikes(this.likesCount);
        } else {
          // console.log('likes havent changed');
        }
      }
    }).catch(error => {
      console.log('checkShouts() fetch Error:', error);
    });
  }

  // shorthand number formatting

  formatLikes(total) {
    if(total >= 1000000) return this.roundToDecimal(total / 1000000, 1) + "M";
    else if(total >= 1000) return this.roundToDecimal(total / 1000, 1) + "K";
    else return total;
  }

  roundToDecimal( inputNum, numPoints ) {
    let multiplier = Math.pow( 10, numPoints );
    return Math.round( inputNum * multiplier ) / multiplier;
  }

}
