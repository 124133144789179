import VaultTextBase from "./VaultTextBase";
import Constants from "../../../shared/constants";
import * as PIXI from 'pixi.js';
import Penner from "../../../vendor/hexademic/Penner";

export default class VaultTextCredit extends VaultTextBase {

  constructor(data) {
    super(data);
    // get quote text for layout
    this.quoteTextSprite = _store.get(Constants.VAULT_QUOTE_TEXT_SPRITE);
    // setup animation
    this.textBlack = this.buildTextSprite('#000000');
  }

  ////////////////////
  // BUILD TEXT
  ////////////////////

  buildTextSprite(textColor, bold=true) {
    // set responsive width for text style
    let wordWrapWidth = this.quoteTextSprite.width;
    let textSize = (this.animData.isPortrait()) ?
      (25/1920) * this.stage.height() :
      (25/1080) * this.stage.height();
    let lineSpacing = (this.animData.isPortrait()) ?
      textSize * 1.35 :
      textSize * 1.35;

    let fontFam = (bold) ? 'HelveticaBold' : 'NeueHelveticaHOI';
    let textStyle = new PIXI.TextStyle({
      fontFamily: fontFam,
      fontSize: textSize * Constants.TEXT_RESOLUTION,
      lineHeight: lineSpacing * Constants.TEXT_RESOLUTION,
      // letterSpacing: this.stage.width() * -0.005,
      fill: textColor,
      wordWrap: true,
      align: 'right',
      wordWrapWidth: wordWrapWidth * Constants.TEXT_RESOLUTION,
    });

    // build text sprite
    let sprite = new PIXI.Text(this.animData.textQuoteAttribution, textStyle);
    sprite.anchor.set(1, 0);
    sprite.scale.set(1 / Constants.TEXT_RESOLUTION); // scale down from retina-style 2x scaling

    // position
    sprite.x = this.quoteTextSprite.x + this.quoteTextSprite.width - textSize;
    if(this.animData.isPortrait()) {
      sprite.y = this.quoteTextSprite.y + this.quoteTextSprite.height + (0.007 * this.stage.height());
    } else {
      sprite.y = this.quoteTextSprite.y + this.quoteTextSprite.height + (0.01 * this.stage.height());
    }

    // add to stage
    this.container.addChild(sprite);
    return sprite;
  }

  ////////////////////
  // ANIMATE
  ////////////////////

  ANIMATION_FRAME(frame) {
    super.ANIMATION_FRAME(frame);

    let easedShow = Penner.easeInOutExpo(this.activeShowEase.value(), 0, 1, 1);
    this.textBlack.alpha = easedShow;
  }

}
