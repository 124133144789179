import VaultVideo from "./VaultVideo";
import VaultImages from "./VaultImages";
import Constants from "../../../shared/constants";
import VaultLogo from "./VaultLogo";
import LoadingStatus from "../../../shared/LoadingStatus";
import VaultTransitionGlitch from "./VaultTransitionGlitch";
import VaultBackground from "./VaultBackground";
import VaultTextProduct from "./VaultTextProduct";
import VaultTextHeadline from "./VaultTextHeadline";
import VaultTextBody from "./VaultTextBody";
import VaultTextQuote from "./VaultTextQuote";
import VaultTextCredit from "./VaultTextCredit";

export default class VaultAnimation {

  constructor() {
    this.buildSlides(_store.get(Constants.DATA_PARSED));
    this.addKeyboardTrigger();
  }

  addKeyboardTrigger() {
    // add space key to toggle public app active interface
    window.addEventListener('keydown', (e) => {
      var key = e.keyCode ? e.keyCode : e.which;
      // console.log(key);
      if(key == 32) {
        e.preventDefault();
        app.toggleActive();
      }
    });
  }

  buildSlides(data) {
    // don't build anything if blank
    if(data.blank) {
      _store.set(Constants.BLANK_PAGE_LOADED, true);
      _store.set(Constants.VAULT_LOADED, true);
      return;
    }

    // build layers
    new VaultBackground(data);

    // build slides defined in JSON
    let slides = data.slides;
    let extraSlides = data.extras;
    if(slides) {
      if(slides.length == 1 && slides[0].subType == "video") {
        new VaultVideo(data, slides[0]);
      } else if(slides.length >= 1) {
        new VaultImages(data, slides, extraSlides);
      }
    }

    // build top layers
    if(!!data.branding) new VaultLogo(data);
    new VaultTextProduct(data);
    new VaultTextHeadline(data);

    // conditional body or quote text - default to using body copy
    if(data.textBodyCopy && data.textBodyCopy.length > 0) {
      new VaultTextBody(data);
    } else if(data.textQuote && data.textQuote.length > 0) {
      new VaultTextQuote(data);
      if(data.textQuoteAttribution && data.textQuoteAttribution.length > 0) {
        new VaultTextCredit(data);
      }
    }
    LoadingStatus.set(LoadingStatus.SLIDES_BUILT);

    // post-processing effects
    new VaultTransitionGlitch();
    LoadingStatus.set(LoadingStatus.GLITCH_BUILT);

    // slides.forEach((slideData, i, arr) => {
    //   if (slideData.subType == "image") {
    //     new SlideImage(slideData);
    //   } else if (slideData.subType == "video") {
    //     new SlideVideo(slideData);
    //   }
    // });

    // auto-show for testing active mode elements:
    // app.setActive(true);

    // TODO: preload for real by counting up loaded elements?
    _store.set(Constants.VAULT_LOADED, true);

    // if demo mode, trigger once and then never again
    if(data.demo == true) {
      setTimeout(() => {
        app.setActive(true);
        data.disabled = true;
      }, 4000);
    }

    // remove loading indicators
    setTimeout(() => {
      LoadingStatus.set(LoadingStatus.COMPLETE);
    }, 4000);
  }

}
