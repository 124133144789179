import SlideVideo from "./SlideVideo";
import SlideModalText from "./SlideModalText";
import SlideTechInfo from "./SlideTechInfo";
import SlideImage from "./SlideImage";
import SlideTitle from "./SlideTitle";
import SlidePreviewImage from "./SlidePreviewImage";
import Constants from "../../../shared/constants";
import LoadingStatus from "../../../shared/LoadingStatus";

export default class SpeedShopAnimation {

  constructor() {
    this.buildSlides(_store.get(Constants.DATA_PARSED));
  }

  buildSlides(data) {
    // don't build anything if blank
    if(data.blank || !data.slides || data.slides.length == 0) {
      _store.set(Constants.BLANK_PAGE_LOADED, true);
      return;
    }

    // adjust text size for ipad
    if (data.isPortrait() == false) Constants.TEXT_IPAD_SCALE = 1.75;

    // change default slide time for slideshow mode
    if(data.animation_type_id == Constants.ANIM_ID_SLIDESHOW) Constants.SLIDE_TIME_IMAGE = 10000;

    if (data.isSlideshow() != true) {
      // build title slide
      new SlideTitle(data);

      // build modals
      new SlideTechInfo(data);
      new SlideModalText(data);
    }

    // build slides defined in JSON
    let slides = data.slides;
    slides.forEach((slideData, i, arr) => {
      if (slideData.subType == "image") {
        let newSlide = new SlideImage(slideData, data);
        if(slideData.primaryColor == false && data.slidePreview == true) new SlidePreviewImage(slideData);
      } else if (slideData.subType == "video") {
        new SlideVideo(slideData);
      }
    });
    LoadingStatus.set(LoadingStatus.SLIDES_BUILT);
  }

}
