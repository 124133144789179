import SlideBase from "./SlideBase";
import Constants from "../../../shared/constants";
import * as PIXI from 'pixi.js';
import LinearFloat from "../../../vendor/hexademic/LinearFloat";
import PixiSpriteScale from "../../../vendor/hexademic/PixiSpriteScale";
import EasingFloat from "../../../vendor/hexademic/EasingFloat";
import Penner from "../../../vendor/hexademic/Penner";

export default class SlideImage extends SlideBase {

  constructor(slideData, slidesData) {
    super();
    this.slideData = slideData;
    this.slidesData = slidesData;
    // console.log(this.slideData);
    // this.slideData.assetUrl = this.slideData.assetUrl.replace('.webm', '.mp4');
    this.buildAnimProps();
    this.buildMask();
    this.loadMedia();

    _store.addListener(this, Constants.TECH_OFFSET);
  }

  ////////////////////
  // MEDIA TYPE / DATA HELPERS
  ////////////////////

  isImage() {
    return this.slideData.subType == "image";
  }

  isVideo() {
    return this.slideData.subType == "video";
  }

  isPrimaryColor() {
    return this.slideData.primaryColor || this.slideData.slideshowOnly;
  }

  ////////////////////
  // INIT MASK & ANIM PROPS
  ////////////////////

  buildMask() {
    this.maskGraphics = new PIXI.Graphics();
    this.maskGraphics.beginFill(0xFF700B, 1);
    this.maskGraphics.drawRect(0, 0, this.stage.width(), this.stage.height());
  }

  buildAnimProps() {
    this.slideTime = this.slideData.duration || Constants.SLIDE_TIME_IMAGE;
    this.transition = this.slideData.transition || Constants.SLIDE_ANIM_SLIDE;
    this.maskShowEase = new LinearFloat(1, 0.015);
    this.imageShowEase = new LinearFloat(1, 0.005);
    this.imageHideEase = new LinearFloat(0, 0.005);
    this.imageTechOffsetEase = new EasingFloat(0, 12);
  }

  ////////////////////
  // LOAD MEDIA
  ////////////////////

  loadMedia() {
    this.loaded = false;
    this.sprite = new PIXI.Sprite.from(this.slideData.assetUrl);
    this.container.addChild(this.sprite);
    this.container.addChild(this.maskGraphics);
  }

  ////////////////////
  // MEDIA LOADED CHECK
  ////////////////////

  isMediaLoaded() {
    return this.slideData.subType == "image" && this.sprite.texture.baseTexture.valid;
  }

  checkLoaded() {
    if (this.loaded) return;
    if (this.isMediaLoaded() && this.sprite) {
      this.loaded = true;
      PixiSpriteScale.scaleToFillContainerMult(this.sprite, this.stage.width(), this.stage.height(), Constants.IMAGE_SLIDE_SCALE);
      this.sprite.x = this.stage.width() / 2;
      this.sprite.y = this.stage.height() / 2;
      this.sprite.mask = this.maskGraphics;
      this.sprite.anchor.set(0.5, 0.5);
      _store.set(Constants.SLIDES_LOADED, _store.get(Constants.SLIDES_LOADED) + 1);
    }
  }

  ////////////////////
  // SHOW/HIDE
  ////////////////////

  show() {
    super.show();
    this.maskShowEase.setValue(1);
    this.maskShowEase.setTarget(0);
    this.imageShowEase.setValue(1);
    this.imageShowEase.setTarget(0);
    this.imageHideEase.setValue(0);
    this.imageHideEase.setTarget(0);
    this.imageTechOffsetEase.setValue(0);
    this.imageTechOffsetEase.setTarget(0);
  }

  hide() {
    super.hide();
    this.imageHideEase.setTarget(1);

    // since last slide doesn't slide away when slide sequence change directions, slide tech offset back into place
    if (this.nextSlide().isPrimaryColor() != this.isPrimaryColor()) {
      this.imageTechOffsetEase.setTarget(0);
    }

    // slide up faster if tech info offset was downward.
    if(this.imageTechOffsetEase.value() > 0) {
      let inc = (this.slidesData.isPortrait()) ? 0.009 : 0.009;
      this.imageHideEase.setInc(inc);
    } else {
      this.imageHideEase.setInc(0.0055);
    }
  }

  TECH_OFFSET(direction) {
    if(this.imageTechOffsetEase.target() == 0) { // only set once per show()
      this.imageTechOffsetEase.setTarget(direction);
      if(direction > 0) this.imageTechOffsetEase.setEaseFactor(10); // 2nd tech info image offset should be faster (not immediate as it used to be with value of 3)
    }
  }

  ////////////////////
  // ANIMATE
  ////////////////////

  ANIMATION_FRAME(frame) {
    this.checkLoaded();
    switch(this.transition) {
      case Constants.SLIDE_ANIM_SLIDE :
        this.animateImageAndMask();
        break;
      case Constants.SLIDE_ANIM_HARD_CUT :
        this.animateHardCut();
        break;
      case Constants.SLIDE_ANIM_CROSSFADE :
        this.animateCrossfade();
        break;
    }
  }

  animateImageAndMask() {
    this.maskShowEase.update();
    this.imageShowEase.update();
    this.imageHideEase.update();
    this.imageTechOffsetEase.update(true);

    // move image & mask layers
    // switch axis based on primary/secondary color
    var axis = (this.isPrimaryColor()) ? 'y' : 'x';
    var dimension = (this.isPrimaryColor()) ? this.stage.height() : this.stage.width();
    if (this.slideData.isIpad) {
      axis = 'x';
      dimension = this.stage.width();
    }

    let maskPos = this.maskShowEase.valuePenner(Penner.easeInOutExpo) * dimension;
    this.maskGraphics[axis] = maskPos;

    let imagePos = this.imageShowEase.valuePenner(Penner.easeInExpo) * dimension * 0.25;
    imagePos += this.imageTechOffsetEase.value() * dimension * 0.17;
    if (this.nextSlide().isPrimaryColor() == this.isPrimaryColor()) {
      // if next slide moves in the same direction, slide this one out when hiding
      imagePos -= this.imageHideEase.valuePenner(Penner.easeInOutQuad) * dimension * 1.5;
    }
    if (this.sprite) this.sprite[axis] = dimension / 2 + imagePos;
  }

  animateHardCut() {
    // do nothing. super.setTopLayer() does the work for us
  }

  animateCrossfade() {
    this.maskShowEase.update();
    if (this.sprite) {
      this.sprite.alpha = 1 - this.maskShowEase.value();  // maskShow is reversed, so let's re-reverse it
    }
  }

}
