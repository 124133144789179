import SlideBase from "./SlideBase";
import * as PIXI from 'pixi.js';
import Constants from "../../../shared/constants";
import LinearFloat from "../../../vendor/hexademic/LinearFloat";
import Penner from "../../../vendor/hexademic/Penner";

export default class SlideModalText extends SlideBase {

  constructor(slidesData) {
    super(false);
    this.slidesData = slidesData;
    this.slideTime = Constants.SLIDE_TIME_TITLE;
    this.buildAnimProps();
    this.buildMask();
    this.buildBackground();
    this.buildTextLayers();
    _store.get(Constants.MODAL_CONTAINER).addChild(this.container);
  }

  ////////////////////
  // INIT MASK & ANIM PROPS
  ////////////////////

  buildMask() {
    this.maskGraphics = new PIXI.Graphics();
    this.maskGraphics.beginFill(0xFF700B, 1);
    this.maskGraphics.drawRect(0, 0, this.stage.width(), this.stage.height());
    _store.get(Constants.MODAL_CONTAINER).addChild(this.maskGraphics);
    this.container.mask = this.maskGraphics;
  }

  buildBackground() {
    this.background = new PIXI.Graphics();
    this.background.beginFill(0xF8F8F8, 1);
    this.background.drawRect(0, 0, this.stage.width(), this.stage.height());
    if(this.slidesData.isPortrait()) this.container.addChild(this.background);
  }

  buildAnimProps() {
    this.maskShowEase = new LinearFloat(1, 0.005);
    this.maskHideEase = new LinearFloat(0, 0.015);
    this.imageShowEase = new LinearFloat(1, 0.005);
    this.imageHideEase = new LinearFloat(0, 0.005);
  }

  buildTextLayers() {
    let textSizeMult = (this.slidesData.isPortrait()) ? 0.9 : 1.18;
    let textWrapWidth = (this.slidesData.isPortrait()) ? 0.42 : 0.5;
    this.headlineText = new PIXI.Text(this.slidesData.textMoreColors, this.getTextStyleBody(false, textSizeMult, textWrapWidth));
    this.textBaseX = this.stage.width() * 0.1;
    this.textBaseY = this.stage.height() * 0.8;
    if (this.slidesData.isPortrait() == false) {
      this.textBaseX = this.stage.width() * 0.08;
      this.textBaseY = this.stage.height() * 0.1;
    }
    this.container.addChild(this.headlineText);
    this.headlineText.scale.set(1 / Constants.TEXT_RESOLUTION);
  }

  ////////////////////
  // SHOW/HIDE
  ////////////////////

  CUR_SLIDE_INDEX(newSlideIndex) {
    let curSlide = _store.get(Constants.SLIDES_ARRAY)[newSlideIndex];
    if (curSlide.isPrimaryColor() == false) {
      if (this.isShowing == false) this.show();
    } else {
      if (this.isShowing) this.hide();
    }
  }

  show() {
    // super.show();
    this.isShowing = true;
    setTimeout(() => {
      this.maskShowEase.setValue(1);
      this.maskShowEase.setTarget(0);
      this.maskHideEase.setValue(0);
      this.maskHideEase.setTarget(0);
      this.imageShowEase.setValue(1);
      this.imageShowEase.setTarget(0);
      this.imageHideEase.setValue(0);
      this.imageHideEase.setTarget(0);
    }, Constants.MODAL_SHOW_DELAY);
  }

  hide() {
    this.isShowing = false;
    this.maskHideEase.setTarget(1);
    this.imageHideEase.setTarget(1);
  }

  ////////////////////
  // ANIMATE
  ////////////////////

  ANIMATION_FRAME(frame) {
    this.animateLayers();
  }

  animateLayers() {
    this.maskShowEase.update();
    this.maskHideEase.update();
    this.imageShowEase.update();
    this.imageHideEase.update();

    // move image & mask layers
    // switch axis based on primary/secondary color
    let axis = (this.slidesData.isPortrait()) ? 'y' : 'x';
    let dimension = (this.slidesData.isPortrait()) ? this.stage.height() : this.stage.width();

    // move mask
    var maskPos = 0;
    if (this.slidesData.isPortrait()) {
      maskPos = dimension * 0.6666 + this.maskShowEase.valuePenner(Penner.easeInExpo) * dimension * 0.3333;
      maskPos -= dimension * 1.6666 * this.maskHideEase.valuePenner(Penner.easeInOutExpo);
    } else {
      maskPos = this.maskShowEase.valuePenner(Penner.easeInExpo) * dimension;
      maskPos -= dimension * this.maskHideEase.valuePenner(Penner.easeInOutExpo);
    }
    this.maskGraphics[axis] = maskPos;


    let imageOffset = this.imageShowEase.valuePenner(Penner.easeInExpo) * dimension * 0.25;
    // if (this.nextSlide().isPrimaryColor() == this.isPrimaryColor()) {
      // if next slide moves in the same direction, slide this one out when hiding
      imageOffset -= this.imageHideEase.valuePenner(Penner.easeOutQuad) * dimension * 0.7;
    // }

    // set headline text position
    if (this.slidesData.isPortrait()) {
      this.headlineText.x = this.textBaseX;
      this.headlineText.y = this.textBaseY + imageOffset;
    } else {
      this.headlineText.x = this.textBaseX + imageOffset;
      this.headlineText.y = this.textBaseY;
    }
  }
}
