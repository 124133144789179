import * as PIXI from 'pixi.js';
import SpeedShopAnimation from "../animations/speedshop/src/SpeedShopAnimation";
import SpeedShopVideoFullscreen from "../animations/speedshop/src/SpeedShopVideoFullscreen";
import DOMUtil from "../vendor/hexademic/DomUtil";
import LoadingStatus from "./LoadingStatus";
import Constants from "./constants";
import AppStoreHax from "../vendor/hexademic/AppStoreHax";
import DataParserV2 from "./DataParserV2";
import DataParser from "./DataParser";
import NewDataChecker from "./NewDataChecker";
import PixiStage from "../vendor/hexademic/PixiStage";
import EasingFloat from "../vendor/hexademic/EasingFloat";
import VaultAnimation from "../animations/vault/src/VaultAnimation";

export default class HyperliveAnimation {

	constructor(el, animationJSON, showStats, staticData = false, pixelRatio = window.devicePixelRatio) {
		this.el = el;
		this.animationJSON = animationJSON;
		this.showStats = showStats;
		DOMUtil.addLoadedClass();
		if (staticData) this.addLoader();
		this.pixelRatio = pixelRatio;
		requestAnimationFrame(() => this.initFonts());
	}

	addLoader() {
		this.hasLoader = true;
		let loadingPanel = document.createElement('div');
		loadingPanel.setAttribute('class', 'loader');
		this.el.appendChild(loadingPanel);
	}

	hideLoader() {
		if (!this.hasLoader) return;
		this.el.classList.add('loaded');
		setTimeout(() => {
			let loadingPanel = this.el.querySelector('.loader');
			if (!!loadingPanel.parentNode) {
				loadingPanel.parentNode.removeChild(loadingPanel);
			}
		}, 1000);
	}

	hasWebGL() {
		var canvas = document.createElement('canvas');
		return !!(window.WebGLRenderingContext && (canvas.getContext('webgl') || canvas.getContext('experimental-webgl')));
	}

	isIOS() {
		var userAgent = navigator.userAgent.toLowerCase()
		if (userAgent.match(/iphone/i)) return true;
		if (userAgent.match(/ipad/i)) return true;
		if (userAgent.match(/ipod/i)) return true;
		if (userAgent.match(/crios/i)) return true;
		return false;
	}

	initFonts() {
		LoadingStatus.set(LoadingStatus.LOAD_FONTS);
		// force font loading
		this.el.innerHTML += ""
			+ '<div class="text-font-1">Text1</div>'
			+ '<div class="text-font-2">Text2</div>'
			+ '<div class="text-font-3">Text3</div>';

		// listen for browser font loaded event
		let fontLoadDelay = (Constants.DEV_MODE) ? 500 : 5000;
		if (this.isIOS() || !document.fonts || document.fonts.status == "loaded") {
			// wait another second to make sure fonts are reallllly loaded...
			setTimeout(() => this.init(), fontLoadDelay);
		} else {
			// modern browsers can use font-loading browser callback
			document.fonts.onloadingdone = (fontFaceSetEvent) => {
				// log font info
				console.log('`document` loaded ' + fontFaceSetEvent.fontfaces.length + ' font faces:');
				fontFaceSetEvent.fontfaces.forEach((el, i) => {
					console.log('- ', el.family);
				});

				// wait another second to make sure fonts are reallllly loaded...
				setTimeout(() => this.init(), fontLoadDelay);
			};
		}
	}

	init() {
		LoadingStatus.set(LoadingStatus.INIT_OBJECTS);
		// init app store
		new AppStoreHax();
		_store.addListener(this);
		// _store.addListener(this, LoadingStatus.COMPLETE);
		// init state for speedshop
		_store.set(Constants.NUM_SLIDES, 0);
		_store.set(Constants.SLIDES_ARRAY, []);
		_store.set(Constants.SLIDES_LOADED, 0);
		_store.set(Constants.CUR_SLIDE_INDEX, -1);
		// init state for vault
		_store.set(Constants.ACTIVE_MODE, false);
		// this.appStoreDebug = new AppStoreDebug();

		// load data and set appropriate formatting classes
		this.dataParser = (!!this.animationJSON.animation_type_id) ?
			new DataParserV2(this.animationJSON) :
			new DataParser(this.animationJSON);
		this.el.classList.add(this.dataParser.containerClass());

		// make sure classes resize DOM and init PIXI
		requestAnimationFrame(() => this.initApp());

		// poll for data updates
		this.dataChecker = new NewDataChecker();
	}

	initApp() {
		this.buildPixiStage();
		this.buildAnimationStyle();
		this.addWindowListeners();
	}

	buildPixiStage() {
		if (this.dataParser.isVideo()) return;

		LoadingStatus.set(LoadingStatus.INIT_STAGE);
		// check for speedshop for PIXI background color
		let bgColor = (this.dataParser.isSpeedshop()) ? 0xffffff : 0x000000

		// init pixi
		this.pixiStage = new PixiStage(this.el, bgColor, 'hyperlive-canvas', this.pixelRatio);
		_store.set(Constants.PIXI_STAGE, this.pixiStage);

		// listen for webgl crashes
		this.pixiStage.app.renderer.view.addEventListener('webglcontextlost', () => {
			document.location.reload();
		});

		// build main pixi container
		LoadingStatus.set(LoadingStatus.INIT_PIXI);
		_store.set(Constants.APP_CONTAINER, new PIXI.Container());
		_store.set(Constants.MODAL_CONTAINER, new PIXI.Container());
		_store.set(Constants.PIP_CONTAINER, new PIXI.Container());
		this.pixiStage.container().addChild(_store.get(Constants.APP_CONTAINER));
		this.pixiStage.container().addChild(_store.get(Constants.MODAL_CONTAINER));
		this.pixiStage.container().addChild(_store.get(Constants.PIP_CONTAINER));

		// prep stats & subscribe to PIXI's raf
		this.startAnimation();
		this.frameListener = this.animate.bind(this);
		this.pixiStage.addFrameListener(this.frameListener);
	}

	buildAnimationStyle() {
		// objects
		// this.loadingSpinner = new LoadingSpinner();
		// init the specified animation style
		if (this.dataParser.isVideo()) {
			this.animation = new SpeedShopVideoFullscreen(this.el);
		} else if (this.dataParser.isSpeedshop()) {
			this.animation = new SpeedShopAnimation();
		} else if (this.dataParser.isHOI()) {
			this.animation = new VaultAnimation();
		}
		LoadingStatus.set(LoadingStatus.ANIMATION_STYLE);
	}

	addWindowListeners() {
		window.addEventListener('resize', () => this.resizeDebounced());
	}

	startAnimation() {
		if (this.showStats) this.buildStats();
		this.prevTime = 0;
		// requestAnimationFrame(() => this.animate());
	}

	buildStats() {
		this.stats = new Stats();
		this.stats.showPanel(0); // 0: fps, 1: ms, 2: mb, 3+: custom
		document.body.appendChild(this.stats.dom);
	}

	// STORE/EVENTS ---------------------

	storeUpdated(key, value) {
		// if(key == SimpleSite.SET_CUR_PATH) page(value);
		// if(key == Constants.LOADING) {
		//   if(value == true) document.body.classList.add('loading');
		//   if(value == false) document.body.classList.remove('loading');
		// }
		if (key == LoadingStatus.STATUS && value == LoadingStatus.COMPLETE) this.hideLoader();
	}

	// PUBLIC INTERFACE ---------------------

	setActive(isActive, sensorId = null) {
		// don't switch if disabled mode (also set by demo mode)
		if (this.dataParser.disabled == true) return;

		// toggle vault mode
		if (isActive == true && _store.get(Constants.ACTIVE_MODE) == false) {
			_store.set(Constants.ACTIVE_MODE, true);
			this.startActiveTimeout();
		} else if (isActive == false && _store.get(Constants.ACTIVE_MODE) == true) {
			_store.set(Constants.ACTIVE_MODE, false);
			window.clearTimeout(this.activeTimeout);
		}
	}

	toggleActive() {
		app.setActive(!_store.get(Constants.ACTIVE_MODE));
	}

	startActiveTimeout() {
		window.clearTimeout(this.activeTimeout);
		this.activeTimeout = setTimeout(() => {
			this.setActive(false);
		}, 3 * 60 * 1000);
	}

	dispose() {
		this.pixiStage.removeFrameListener(this.frameListener);
	}

	// ANIMATE -----------------------

	animate() {
		this.perfStart();
		// requestAnimationFrame(() => this.animate());
		_store.set(Constants.ANIMATION_FRAME, _store.get(Constants.ANIMATION_FRAME) + 1 || 1);
		// this.pixiStage.render();
		this.perfEnd();
		if (this.pixiStage && this.pixiStage.app.renderer.gl.isContextLost()) document.location.reload();
	}

	perfStart() {
		if (!this.fpsEased) this.fpsEased = new EasingFloat(60, 15);
		if (this.stats) this.stats.begin();
	}

	perfEnd() {
		if (this.stats) this.stats.end();
		this.time = (performance || Date).now();
		this.fpsEased.setTarget(1000 / (this.time - this.prevTime));
		this.fpsEased.update();
		_store.set(Constants.FPS, Math.round(this.fpsEased.value()));
		this.prevTime = this.time;
	}

	// RESIZE -----------------------

	resizeDebounced(e) {
		clearTimeout(this.resizeTimeout);
		this.resizeTimeout = setTimeout(() => this.resize(), 50);
	}

	resize() {
		_store.set(Constants.RESIZE, true);
		// FOR DEBUGGING LOST WEBGL CONTEXT:
		// this.pixiStage.app.renderer.view.dispatchEvent(new CustomEvent('webglcontextlost'));
	}

}
