import * as PIXI from 'pixi.js';
import SlideImage from './SlideImage';
import VideoToBlob from '../../../vendor/hexademic/VideoToBlob';
import Constants from '../../../shared/constants';
import PixiSpriteScale from '../../../vendor/hexademic/PixiSpriteScale';

export default class SlideVideo extends SlideImage {

  constructor(slideData) {
    super(slideData);
  }

  ////////////////////
  // LOAD MEDIA
  ////////////////////

  loadMedia() {
    this.loaded = false;
    this.playStartCurTime = 0;
    this.videoEl = document.createElement('video'); // dummy video element until the real thing is loaded
    if (!this.slideData.isIpad) this.slideData.assetUrl = this.slideData.assetUrl.replace('.mp4', '.webm');

    // load video via xhr ---------------------------
    new VideoToBlob(this.slideData.assetUrl, (videoEl) => {
      // store video element w/blob src
      this.videoEl = videoEl;
      this.videoEl.setAttribute('autoplay', "true");
      this.buildVideoTexture();
    });

    // debug helpers --------------
    // document.body.appendChild(this.videoEl);  // show video in DOM for debugging
    // this.videoEl.play(); // if play is called immediately, it kills the ability to display (?!)
  }

  buildVideoTexture() {
    var texture = PIXI.Texture.from(this.videoEl);
    texture.baseTexture.resource.updateFPS = 30;
    // this.videoEl.play();  // force load
    this.sprite = new PIXI.Sprite(texture);
    this.container.addChild(this.sprite);
    this.container.addChild(this.maskGraphics);

    // just set to loaded
    _store.set(Constants.SLIDES_LOADED, _store.get(Constants.SLIDES_LOADED) + 1);
  }

  ////////////////////
  // MEDIA LOADED CHECK
  ////////////////////

  isMediaLoaded() {
    return true;
    // let loaded = this.slideData.subType == "video" && this.sprite && this.sprite.texture && this.sprite.width > 10;
    // if (loaded) this.slideTime = (this.videoEl.duration * 1000) - 750;   // video.duration is in seconds
    return loaded;
  }

  videoLoaded() {
    return this.slideData.subType == "video" && this.sprite && this.sprite.texture && this.sprite.width > 10;
  }

  ////////////////////
  // SHOW/HIDE
  ////////////////////

  show() {
    super.show();
    this.videoEl.currentTime = 0;
    this.playStartCurTime = this.videoEl.currentTime;
    this.videoEl.play();
    this.videoEl.volume = 0;
    this.videoEl.muted = true;
  }

  hide() {
    super.hide();
    // check for stalled video
    // console.log(this.videoEl.currentTime, ' ==? ', this.playStartCurTime);
    if(this.videoEl.currentTime == this.playStartCurTime) {
      // alert('Video has not played!');
    }
  }

  ANIMATION_FRAME(frameCount) {
    super.ANIMATION_FRAME(frameCount);
    this.slideTime = (this.slideData.duration) ?
                      this.slideData.duration :
                      (this.videoEl.duration * 1000) - 750;   // video.duration is in seconds
  }

  checkLoaded() {
    if (this.sprite) {
      this.loaded = true;
      PixiSpriteScale.scaleToFillContainerMult(this.sprite, this.stage.width(), this.stage.height(), Constants.IMAGE_SLIDE_SCALE);
      this.sprite.x = this.stage.width() / 2;
      this.sprite.y = this.stage.height() / 2;
      this.sprite.mask = this.maskGraphics;
      this.sprite.anchor.set(0.5, 0.5);
    }

  }

}
