import PixiSpriteScale from "../../../vendor/hexademic/PixiSpriteScale";
import Penner from "../../../vendor/hexademic/Penner";

export default class VaultLogo {

  constructor(data) {
    this.animData = data;

    // event listeners
    _store.addListener(this, Constants.ANIMATION_FRAME);
    _store.addListener(this, Constants.ACTIVE_MODE);

    // init container
    this.stage = _store.get(Constants.PIXI_STAGE);
    this.container = new PIXI.Container();
    _store.get(Constants.APP_CONTAINER).addChild(this.container);

    // setup animation
    this.buildAnimProps();
    this.loadMedia(data);
  }

  buildAnimProps() {
    this.imageShowEase = new LinearFloat(0, 0.025);
  }

  // PIXI graphics helpers

  setTopLayer() {
    _store.get(Constants.APP_CONTAINER).addChild(this.container);
  }

  ////////////////////
  // LOAD MEDIA
  ////////////////////

  loadMedia() {
    this.loaded = false;
    let whiteImg = (!!this.animData.assets['logo']) ? this.animData.assets['logo']['white'] : this.animData.assets['logoWhite']; // backgrounds api compatible
    this.spriteWhite = new PIXI.Sprite.from(whiteImg);
    this.container.addChild(this.spriteWhite);

    let blackImg = (!!this.animData.assets['logo']) ? this.animData.assets['logo']['black'] : this.animData.assets['logoBlack'];
    this.spriteBlack = new PIXI.Sprite.from(blackImg);
    this.container.addChild(this.spriteBlack);
  }

  ////////////////////
  // MEDIA LOADED CHECK
  ////////////////////

  isMediaLoaded() {
    return this.spriteBlack.texture.baseTexture.valid && this.spriteWhite.texture.baseTexture.valid;
  }

  checkLoaded() {
    if (this.loaded) return;
    if (this.isMediaLoaded()) {
      this.loaded = true;
      this.setBgImageProps(this.spriteBlack);
      this.setBgImageProps(this.spriteWhite);
    }
  }

  setBgImageProps(sprite) {
    // scale & position for landscape
    sprite.anchor.set(0.5, 0.5);
    if(this.animData.isPortrait()) {
      PixiSpriteScale.scaleToHeight(sprite, (212/1920) * this.stage.height())
      sprite.x = (298/1080) * this.stage.width();
      sprite.y = (182/1920) * this.stage.height();
    } else {
      PixiSpriteScale.scaleToHeight(sprite, (208/1080) * this.stage.height())
      sprite.x = (1774/1920) * this.stage.width();
      sprite.y = (135/1080) * this.stage.height();
    }
  }

  ////////////////////
  // SHOW/HIDE
  ////////////////////

  ACTIVE_MODE(isActive) {
    if(isActive) {
      this.show();
    } else {
      this.hide();
    }
  }

  show() {
    // super.show();
    this.imageShowEase.setTarget(1);
  }

  hide() {
    // super.hide();
    this.imageShowEase.setTarget(0);
  }

  ////////////////////
  // ANIMATE
  ////////////////////

  ANIMATION_FRAME(frame) {
    this.checkLoaded();
    this.imageShowEase.update();
    let easedShow = Penner.easeInOutExpo(this.imageShowEase.value(), 0, 1, 1);
    this.spriteWhite.alpha = 1. - easedShow;
    this.spriteBlack.alpha = easedShow;
  }

}
