import SlideBase from "./SlideBase";
import Constants from "../../../shared/constants";
import * as PIXI from 'pixi.js';
import EasingFloat from "../../../vendor/hexademic/EasingFloat";

export default class SlideTechInfo extends SlideBase {

  constructor(slidesData) {
    super(false);
    this.slidesData = slidesData;
    this.slideTime = Constants.SLIDE_TIME_TITLE;
    this.buildAnimProps();
    this.buildMask();
    this.buildBackground();
    this.buildTextLayers();
    this.buildOutline();
    _store.get(Constants.MODAL_CONTAINER).addChild(this.container);
  }

  ////////////////////
  // INIT MASK & ANIM PROPS
  ////////////////////

  buildMask() {
    this.maskGraphics = new PIXI.Graphics();
    this.maskGraphics.beginFill(0xFF700B, 1);
    this.maskGraphics.drawRect(0, 0, this.stage.width(), this.stage.height());
    _store.get(Constants.MODAL_CONTAINER).addChild(this.maskGraphics);
    this.container.mask = this.maskGraphics;
  }

  buildBackground() {
    this.background = new PIXI.Graphics();
    this.background.beginFill(0xF8F8F8, 1);
    this.background.drawRect(0, 0, this.stage.width(), this.stage.height());
    this.container.addChild(this.background);

    // add overlay hack for 1px mask bug in PIXI
    this.bgOverlay = new PIXI.Graphics();
    this.bgOverlay.beginFill(0xF8F8F8, 1);
    this.bgOverlay.drawRect(0, 0, this.stage.width(), 2);
    if(this.slidesData.isPortrait()) {
      _store.get(Constants.MODAL_CONTAINER).addChild(this.bgOverlay);
    }
  }

  buildOutline() {
    // overlay entire stage with an outline
    let outlineGraphics = new PIXI.Graphics();
    outlineGraphics.lineStyle(1, 0xF8F8F8, 1);
    outlineGraphics.drawRect(1, 0, this.stage.width() - 1, this.stage.height() - 1);
    _store.get(Constants.MODAL_CONTAINER).addChild(outlineGraphics);
  }

  buildAnimProps() {
    this.maskPositionEase = new EasingFloat(1, 12);
    this.maskOverlayEase = new EasingFloat(0, 12);

    this.text1HeadlineShow = new EasingFloat(1, 11);
    this.text1BodyShow = new EasingFloat(1, 14);
    this.text2HeadlineShow = new EasingFloat(1, 11);
    this.text2BodyShow = new EasingFloat(1, 14);
  }

  buildTextLayers() {
    let textWidth = (this.slidesData.isPortrait()) ? 0.7 : 0.26;
    let textSizeMult = (this.slidesData.isPortrait()) ? 1 : 0.8;

    // tech 1
    if(this.slidesData.textTech1Headline && this.slidesData.textTech1Headline.length > 0) {
      this.tech1HeadlineText = new PIXI.Text(this.slidesData.textTech1Headline, this.getTextStyleBody(true, textSizeMult, textWidth));
      this.tech1HeadlineTextX = (this.slidesData.isPortrait()) ? this.stage.width() * 0.1 : this.stage.width() * 0.72;
      // this.tech1HeadlineTextY = (this.slidesData.isPortrait()) ? this.stage.height() * 0.71 : this.stage.height() * 0.37;
      this.container.addChild(this.tech1HeadlineText);
      this.tech1HeadlineText.scale.set(1 / Constants.TEXT_RESOLUTION);
    }
    if(this.slidesData.textTech1BodyCopy && this.slidesData.textTech1BodyCopy.length > 0) {
      this.tech1BodyText = new PIXI.Text(this.slidesData.textTech1BodyCopy, this.getTextStyleBody(false, textSizeMult, textWidth));
      this.tech1BodyTextX = (this.slidesData.isPortrait()) ? this.stage.width() * 0.1 : this.stage.width() * 0.72;
      // this.tech1BodyTextY = this.tech1HeadlineTextY + this.tech1HeadlineText.height + this.tech1HeadlineText.style.fontSize / Constants.TEXT_RESOLUTION;
      this.container.addChild(this.tech1BodyText);
      this.tech1BodyText.scale.set(1 / Constants.TEXT_RESOLUTION);
    }

    // tech 2
    if(this.slidesData.textTech2Headline && this.slidesData.textTech2Headline.length > 0) {
      this.tech2HeadlineText = new PIXI.Text(this.slidesData.textTech2Headline, this.getTextStyleBody(true, textSizeMult, textWidth));
      this.tech2HeadlineTextX = (this.slidesData.isPortrait()) ? this.stage.width() * 0.1 : this.stage.width() * 0.05;
      // this.tech2HeadlineTextY = (this.slidesData.isPortrait()) ? this.stage.height() * 0.14 : this.stage.height() * 0.37;
      this.container.addChild(this.tech2HeadlineText);
      this.tech2HeadlineText.scale.set(1 / Constants.TEXT_RESOLUTION);
    }
    if(this.slidesData.textTech2BodyCopy && this.slidesData.textTech2BodyCopy.length > 0) {
      this.tech2BodyText = new PIXI.Text(this.slidesData.textTech2BodyCopy, this.getTextStyleBody(false, textSizeMult, textWidth));
      this.tech2BodyTextX = (this.slidesData.isPortrait()) ? this.stage.width() * 0.1 : this.stage.width() * 0.05;
      // this.tech2BodyTextY = this.tech2HeadlineTextY + this.tech2HeadlineText.height + this.tech2HeadlineText.style.fontSize / Constants.TEXT_RESOLUTION;
      this.container.addChild(this.tech2BodyText);
      this.tech2BodyText.scale.set(1 / Constants.TEXT_RESOLUTION);
    }
  }

  ////////////////////
  // SHOW/HIDE
  ////////////////////

  CUR_SLIDE_INDEX(newSlideIndex) {
    let curSlide = _store.get(Constants.SLIDES_ARRAY)[newSlideIndex];
    if(newSlideIndex == 2 && curSlide.isPrimaryColor() && (this.tech1HeadlineText || this.tech1BodyText)) {
      this.show();
    } else if(newSlideIndex == 3 && curSlide.isPrimaryColor() && (!this.tech2HeadlineText && !this.tech2BodyText)) {
      // delayed hide() if there's only 1 tech text to show
      setTimeout(() => this.hide(), 500);
    } else if(newSlideIndex == 3 && curSlide.isPrimaryColor() && (this.tech2HeadlineText || this.tech2BodyText)) {
      this.showStage2();
    } else if (this.isShowing) {
      setTimeout(() =>  this.hide(), 150);
    }
    // if there's not a 3rd primary slide, but there is 2nd tech info, just remove it so we never see it
    if(newSlideIndex == 3 && curSlide.isPrimaryColor() == false) {
      if(this.tech2HeadlineText) this.container.removeChild(this.tech2HeadlineText);
      if(this.tech2BodyText) this.container.removeChild(this.tech2BodyText);
    }
  }

  show() {
    // super.show();
    this.isShowing = true;
    setTimeout(() => {
      // move overall mask
      this.maskPositionEase.setValue(1);
      let easeTarget = (this.slidesData.isPortrait()) ? 0.6666 : 0.6666;
      this.maskPositionEase.setTarget(easeTarget);

      // move text
      this.text1HeadlineShow.setValue(1);
      this.text1HeadlineShow.setTarget(0);
      this.text1BodyShow.setValue(1);
      this.text1BodyShow.setTarget(0);

      // tell the current slide to shift up/left
      _store.set(Constants.TECH_OFFSET, -1);
    }, Constants.MODAL_SHOW_DELAY);
  }

  showStage2() {
    setTimeout(() => {
      // move mask to 2nd info
      let easeTarget = (this.slidesData.isPortrait()) ? -0.6666 : -0.6666;
      this.maskPositionEase.setTarget(easeTarget);

      this.maskOverlayEase.setTarget(1);

      // move text
      this.text1HeadlineShow.setTarget(-1);
      this.text1BodyShow.setTarget(-1);

      this.text2HeadlineShow.setValue(1);
      this.text2HeadlineShow.setTarget(0);
      this.text2BodyShow.setValue(1);
      this.text2BodyShow.setTarget(0);

      // tell the current slide to shift up/left
      _store.set(Constants.TECH_OFFSET, 1);
    }, Constants.MODAL_SHOW_DELAY_STAGE_2);
  }

  hide() {
    this.isShowing = false;
    // move mask all the way off
    this.maskPositionEase.setTarget(-1.2);

    this.maskOverlayEase.setTarget(0);

    // move text
    this.text1HeadlineShow.setTarget(-1);
    this.text1BodyShow.setTarget(-1);
    this.text2HeadlineShow.setTarget(-1);
    this.text2BodyShow.setTarget(-1);
  }

  ////////////////////
  // ANIMATE
  ////////////////////

  ANIMATION_FRAME(frame) {
    this.animateLayers();
  }

  animateLayers() {
    // move image & mask layers
    // switch axis based on primary/secondary color
    let axis = (this.slidesData.isPortrait()) ? 'y' : 'x';
    let dimension = (this.slidesData.isPortrait()) ? this.stage.height() : this.stage.width();

    // move mask
    this.maskPositionEase.update(true);
    let maskPos = dimension * this.maskPositionEase.value();
    this.maskGraphics[axis] = maskPos;

    // overlay because PIXI masking is slightly off/broken
    this.maskOverlayEase.update(true);
    if(this.slidesData.isPortrait()) {
      this.bgOverlay.position.y = -4 + 4 * this.maskOverlayEase.value();
    }

    // calculate text 1 positions
    let textSpacing1 = (this.slidesData.isPortrait()) ? this.stage.height() * 0.025 : this.stage.height() * 0.03;
    let textStartY1 = (this.slidesData.isPortrait()) ? this.stage.height() * (0.666 + 0.333 / 2) : this.stage.height() / 2;
    if(this.tech1HeadlineText && !this.tech1BodyText) {
      this.tech1HeadlineTextY = textStartY1 - this.tech1HeadlineText.height / 2;
    } else if(!this.tech1HeadlineText && this.tech1BodyText) {
      this.tech1BodyTextY = textStartY1 - this.tech1BodyText.height / 2;
    } else if(this.tech1HeadlineText && this.tech1BodyText) {
      let text1HeightTotal = this.tech1HeadlineText.height + this.tech1BodyText.height + textSpacing1;
      this.tech1HeadlineTextY = textStartY1 - text1HeightTotal / 2;
      this.tech1BodyTextY = this.tech1HeadlineText.y + this.tech1HeadlineText.height + textSpacing1;
    }

    // set text 1 positions
    if(this.tech1HeadlineText) {
      this.text1HeadlineShow.update(true);
      this.tech1HeadlineText.x = this.tech1HeadlineTextX + ((this.slidesData.isPortrait()) ? 0 : this.text1HeadlineShow.value() * this.stage.width() * 0.1);
      this.tech1HeadlineText.y = this.tech1HeadlineTextY + ((this.slidesData.isPortrait()) ? this.text1HeadlineShow.value() * this.stage.height() * 0.1 : 0);
      this.tech1HeadlineText.alpha = 1. - Math.abs(this.text1HeadlineShow.value());
    }
    if(this.tech1BodyText) {
      this.text1BodyShow.update(true);
      this.tech1BodyText.x = this.tech1BodyTextX + ((this.slidesData.isPortrait()) ? 0 : this.text1BodyShow.value() * this.stage.width() * 0.1);
      this.tech1BodyText.y = this.tech1BodyTextY + ((this.slidesData.isPortrait()) ? this.text1BodyShow.value() * this.stage.height() * 0.1 : 0);
      this.tech1BodyText.alpha = 1. - Math.abs(this.text1BodyShow.value());
    }

    // calculate text 2 positions
    let textSpacing2 = (this.slidesData.isPortrait()) ? this.stage.height() * 0.025 : this.stage.height() * 0.03;
    let textStartY2 = (this.slidesData.isPortrait()) ? this.stage.height() * (0.333 - 0.333 / 2) : this.stage.height() / 2;
    if(this.tech2HeadlineText && !this.tech2BodyText) {
      this.tech2HeadlineTextY = textStartY2 - this.tech2HeadlineText.height / 2;
    } else if(!this.tech2HeadlineText && this.tech2BodyText) {
      this.tech2BodyTextY = textStartY2 - this.tech2BodyText.height / 2;
    } else if(this.tech2HeadlineText && this.tech2BodyText) {
      let text2HeightTotal = this.tech2HeadlineText.height + this.tech2BodyText.height + textSpacing2;
      this.tech2HeadlineTextY = textStartY2 - text2HeightTotal / 2;
      this.tech2BodyTextY = this.tech2HeadlineText.y + this.tech2HeadlineText.height + textSpacing2;
    }

    // text 2 positions
    if(this.tech2HeadlineText) {
      this.text2HeadlineShow.update(true);
      this.tech2HeadlineText.x = this.tech2HeadlineTextX + ((this.slidesData.isPortrait()) ? 0 : this.text2HeadlineShow.value() * this.stage.width() * 0.1);
      this.tech2HeadlineText.y = this.tech2HeadlineTextY + ((this.slidesData.isPortrait()) ? this.text2HeadlineShow.value() * this.stage.height() * 0.1 : 0);
      this.tech2HeadlineText.alpha = 1. - Math.abs(this.text2HeadlineShow.value());
    }
    if(this.tech2BodyText) {
      this.text2BodyShow.update(true);
      this.tech2BodyText.x = this.tech2BodyTextX + ((this.slidesData.isPortrait()) ? 0 : this.text2BodyShow.value() * this.stage.width() * 0.1);
      this.tech2BodyText.y = this.tech2BodyTextY + ((this.slidesData.isPortrait()) ? this.text2BodyShow.value() * this.stage.height() * 0.1 : 0);
      this.tech2BodyText.alpha = 1. - Math.abs(this.text2BodyShow.value());
    }
  }
}
