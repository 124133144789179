import HyperliveAnimation from "../shared/HyperliveAnimation";
import LoadingStatus from "../shared/LoadingStatus";
import Constants from "../shared/constants";
import DOMUtil from "../vendor/hexademic/DomUtil";
import { getAnimation, getScreenConfigByDisplayId } from "../../../api";

export default class AppBootstrap {

	constructor(el, apiURL = null, jsonData = null, pixelRatio = window.devicePixelRatio) {
		LoadingStatus.set(LoadingStatus.BOOTSTRAP);
		setTimeout(() => document.body.classList.add('no-debug'), 60 * 1000);
		this.appContainer = el;
		if (apiURL) Constants.API_URL = apiURL;
		this.staticData = jsonData;
		this.pixelRatio = pixelRatio;
		window.online = true;
		setTimeout(() => {
			this.onLoad();  // wait for fonts?
		}, 500);
		window.addEventListener('popstate', () => document.location.reload());  // reload page on browser back button
	}

	onLoad() {
		this.getQueryParams();
		this.loadData();
	}

	getQueryParams() {
		this.localhost = window.location.href.match(/localhost/) != null;

		this.format = this.getQueryVariable('format');
		this.devMode = this.getQueryVariable('dev') == 'true';
		if (this.devMode) Constants.DEV_MODE = true;
		this.showStats = this.getQueryVariable('stats') == 'true';
		if (!this.localhost) this.showStats = false;
		this.showList = this.getQueryVariable('showList') == 'true';
		this.showLoader = this.getQueryVariable('showLoader') == 'true';
		this.containerOverride = this.getQueryVariable('containerOverride');
		this.pixelRatio = this.getQueryVariable('pixelRatio') ? parseFloat(this.getQueryVariable('pixelRatio')) : this.pixelRatio;
		this.animId = this.getQueryVariable('anim') || this.getQueryVariable('animation');
		this.displayId = this.getQueryVariable('display');

		this.localData = this.getQueryVariable('localData');
		this.queryVersion = Math.round(Math.random() * 999999);

		window.videoOnly = this.getQueryVariable('videoOnly') == 'true';
		window.slideshowOnly = this.getQueryVariable('slideshowOnly') == 'true';
		window.slidePreview = this.getQueryVariable('slidePreview') == 'true';
		window.exportVideo = this.getQueryVariable('exportVideo') == 'true';
	}

	loadData() {
		// use static data if it was passed in. short-circuit API data
		if (this.staticData) {
			Constants.DEV_MODE = true;
			return this.loadAnimationJSON(this.staticData);
		}

		// otherwise load via API
		LoadingStatus.set(LoadingStatus.REQUEST_DATA);
		// if we've passed an animation id, load that data
		// otherwise, load the screen config to find the animation id
		if (this.localData) {
			this.loadLocalData();
		} else if (this.animId) {
			this.loadAnimationData();
		} else if (this.displayId) { // OLD: this.storeId && this.zoneId &&
			this.loadScreenConfig();  // get animation id from api
		} else if (this.showList) {
			new AnimationsList();
		} else {

		}
	}

	loadScreenConfig() {
		Constants.SCREEN_CONFIG_URL = `${Constants.API_URL}api/public/display/${this.displayId}?v=${this.queryVersion}`;

		getScreenConfigByDisplayId({
			baseURL: Constants.API_URL,
			displayId: this.displayId,
			version: this.queryVersion
		})
		.then((jsonData) => {
			this.parseScreenConfigJSON(jsonData);
		}).catch(error => {
			this.jsonLoadFailed();
		});
	}

	parseScreenConfigJSON(data) {
		this.animId = data.animation_id;
		Constants.SCREEN_CONFIG_ANIM_ID = data.animation_id;
		this.loadAnimationData();
	}

	loadAnimationData() {
		// show error is no animation id is set
		if (this.animId == null) {
			this.dataChecker = new NewDataChecker();
			this.dataChecker.restartInterval();
			// this.appContainer.innerHTML = '<p style="color:red;padding:50px">Null Animation ID<p>';
			return;
		}
		Constants.ANIMATION_DATA_URL = `${Constants.API_URL}api/public/animation/${this.animId}?v=${this.queryVersion}`;
		Constants.ANIMATION_DATA_URL_STORAGE_KEY = `animation-${this.animId}`;

		console.log(Constants.ANIMATION_DATA_URL);
		getAnimation({
		    baseURL: Constants.API_URL,
		    animationId: this.animId,
		    version: this.queryVersion
		})
		.then((jsonData) => { this.loadAnimationJSON(jsonData); })
		.catch(() => { this.jsonLoadFailed(); });
	}

	loadLocalData() {
		Constants.ANIMATION_DATA_URL = `./data/${this.localData}.json`;
		fetch(Constants.ANIMATION_DATA_URL)
			.then((response) => {
				return response.json();
			}).then((jsonData) => {
			this.loadAnimationJSON(jsonData);
		}).catch(error => {
			this.jsonLoadFailed();
		});
	}

	loadAnimationJSON(data) {
		// check for json error - no animation data found
		if (data.statusCode == 404) {
			LoadingStatus.set(LoadingStatus.NO_ANIM_DATA);
			return;
		}

		// continue loading with good data
		LoadingStatus.set(LoadingStatus.MAIN_CLASS_INIT);
		Constants.ANIMATION_DATA_UPDATED_AT = data.updated_at;
		if (this.devMode && this.localhost) {
			// add special css classes for dev
			this.appContainer.classList.add('dev');
			document.body.classList.add('dev');
			if (this.containerOverride) {
				this.appContainer.classList.add(this.containerOverride);
			}
		} else {
			let linksEl = document.querySelector('#links-container');
			if (linksEl) DOMUtil.remove(linksEl);
		}
		window.app = new HyperliveAnimation(this.appContainer, data, this.showStats, !!this.staticData || this.showLoader, this.pixelRatio);
	}

	getQueryVariable(variable) {
		var query = window.location.hash.substring(1);
		var vars = query.split('&');
		for (var i = 0; i < vars.length; i++) {
			var pair = vars[i].split('=');
			if (decodeURIComponent(pair[0]) == variable) {
				return decodeURIComponent(pair[1]);
			}
		}
		return null;
	}

	jsonLoadFailed() {
		setTimeout(() => {
			// document.location.reload()
			this.loadData();
			// LoadingStatus.set(LoadingStatus.JSON_INIT_FAILED);
		}, 15000);
	}

}

window.AppBootstrap = AppBootstrap;

// let api = 'https://api.staging.nikehyperlive.com/';
// new AppBootstrap(document.querySelector('.hyperlive-container'), api);
