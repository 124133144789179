import Constants from "../../../shared/constants";
import * as PIXI from 'pixi.js';

export default class SlideBase {

  constructor(addSlide = true) {
    // event listeners
    _store.addListener(this, Constants.ANIMATION_FRAME);
    _store.addListener(this, Constants.SLIDES_LOADED);
    _store.addListener(this, Constants.CUR_SLIDE_INDEX);

    // init container
    this.stage = _store.get(Constants.PIXI_STAGE);
    this.container = new PIXI.Container();
    // increment slides loaded & add to array
    this.slideIndex = -1;
    this.slideTime = 99999;
    if (addSlide) {
      this.slideIndex = _store.get(Constants.NUM_SLIDES);
      _store.set(Constants.NUM_SLIDES, this.slideIndex + 1);
      _store.get(Constants.SLIDES_ARRAY).push(this);
    }

    // init props
    this.isShowing = false;
  }

  // when loading is finished, start slideshow from the first slide

  SLIDES_LOADED(numLoaded) {
    if(this.slideIndex == 0) {
      if (numLoaded > 1 && numLoaded == _store.get(Constants.NUM_SLIDES)) {   // > 1 because first slide is the title slide, and that loading is immediate
        this.incrementSlide();
      }
    }
  }

  // PIXI graphics helpers

  setTopLayer() {
    _store.get(Constants.APP_CONTAINER).addChild(this.container);
  }

  getTextStyleHeadline(horizPadding, resolution = Constants.TEXT_RESOLUTION) {
    let fontSize = this.stage.height() * 0.06 * resolution * Constants.TEXT_IPAD_SCALE;
    return new PIXI.TextStyle({
      fontFamily: 'HelveticaBold', // Should be Helvetica Bold
      fontSize: fontSize,
      lineHeight: this.stage.height() * 0.075 * resolution * Constants.TEXT_IPAD_SCALE,
      letterSpacing: this.stage.height() * -0.003 * resolution * Constants.TEXT_IPAD_SCALE,
      fill: Constants.TEXT_FILL_COLOR,
      wordWrap: true,
      wordWrapWidth: this.stage.width() * (1. - horizPadding) * resolution,
      padding: fontSize * 0.1
    });
  }

  getTextStyleBody(bold=false, sizeMult=1., wordWrapScreenWidth=0.7) {
    let fontFam = (bold) ? 'HelveticaBold' : 'Helvetica';
    return new PIXI.TextStyle({
      fontFamily: fontFam,
      fontSize: this.stage.height() * 0.027 * Constants.TEXT_RESOLUTION * Constants.TEXT_IPAD_SCALE * sizeMult,
      lineHeight: this.stage.height() * 0.036 * Constants.TEXT_RESOLUTION * Constants.TEXT_IPAD_SCALE * sizeMult,
      // letterSpacing: this.stage.height() * -0.003,
      fill: Constants.TEXT_FILL_COLOR,
      wordWrap: true,
      wordWrapWidth: this.stage.width() * wordWrapScreenWidth * Constants.TEXT_RESOLUTION,
    });
  }

  getTextStyleSmallBold(bold=true) {
    let fontFam = (bold) ? 'HelveticaBold' : 'Helvetica';
    return new PIXI.TextStyle({
      fontFamily: fontFam,
      fontSize: this.stage.height() * 0.023 * Constants.TEXT_RESOLUTION * Constants.TEXT_IPAD_SCALE,
      lineHeight: this.stage.height() * 0.027 * Constants.TEXT_RESOLUTION * Constants.TEXT_IPAD_SCALE,
      // letterSpacing: this.stage.height() * -0.003,
      fill: Constants.TEXT_FILL_COLOR,
      wordWrap: true,
      wordWrapWidth: this.stage.width() * 0.7 * Constants.TEXT_RESOLUTION,
    });
  }

  getTextStyleIcon(textSizeMult, bold=true) {
    // let fontFam = (bold) ? 'HelveticaBold' : 'Helvetica';
    return new PIXI.TextStyle({
      fontFamily: 'Arial',
      fontSize: this.stage.height() * textSizeMult * Constants.TEXT_RESOLUTION * Constants.TEXT_IPAD_SCALE,
      lineHeight: this.stage.height() * 0.027 * Constants.TEXT_RESOLUTION * Constants.TEXT_IPAD_SCALE,
      fill: Constants.TEXT_FILL_COLOR,
    });
  }

  // Slide getters

  numSlides() {
    return _store.get(Constants.SLIDES_ARRAY).length;
  }

  prevSlide() {
    let nextSlideIndex = this.slideIndex - 1;
    if (nextSlideIndex < 0) nextSlideIndex = this.numSlides() - 1;
    return _store.get(Constants.SLIDES_ARRAY)[nextSlideIndex];
  }

  nextSlide() {
    var nextSlideIndex = this.slideIndex + 1;
    if (nextSlideIndex >= this.numSlides()) nextSlideIndex = 0;
    return _store.get(Constants.SLIDES_ARRAY)[nextSlideIndex];
  }

  curSlide() {
    return _store.get(Constants.SLIDES_ARRAY)[this.slideIndex];
  }

  // global go to next slide + listener

  incrementSlide() {
    let nextSlideIndex = _store.get(Constants.CUR_SLIDE_INDEX) + 1;
    if (nextSlideIndex >= this.numSlides()) nextSlideIndex = 0;
    _store.set(Constants.CUR_SLIDE_INDEX, nextSlideIndex);
  }

  CUR_SLIDE_INDEX(newSlideIndex) {
    if (newSlideIndex == this.slideIndex) {
      this.show();
    } else {
      if (this.isShowing) this.hide();
    }
  }

  // shared show/hide for normal Image/video/title slides

  show(increments=true) {
    this.isShowing = true;
    requestAnimationFrame(() => this.setTopLayer());
    if(increments) setTimeout(() => this.incrementSlide(), this.slideTime);
  }

  hide() {
    this.isShowing = false;
  }

}
