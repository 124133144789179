import Constants from "../../../shared/constants";
import * as PIXI from 'pixi.js';
import LinearFloat from "../../../vendor/hexademic/LinearFloat";
import PixiSpriteScale from "../../../vendor/hexademic/PixiSpriteScale";
import Penner from "../../../vendor/hexademic/Penner";

export default class VaultImages {

  constructor(data, slides, extraSlides=null) {
    this.data = data;
    this.slides = slides;
    this.extraSlides = extraSlides;

    _store.addListener(this, Constants.ACTIVE_MODE);

    this.buildImages();
  }

  checkSwipeMode() {
    // special portrait high heat template flag - v1 vs v2 data
    if(this.data.animation_type_id == Constants.ANIM_ID_VISIBLE_HEAT) {
      // set high heat portrait template mode
      this.mode = this.MODE_LARGE_SWIPE;
      // if only one slide was uploaded, since  we're swiping, duplicate the single slide so animation doesn't look janky
      if(this.slides.length == 1) this.slides.push(this.slides[this.slides.length - 1]);
    }
    // then get the images from extra arrays
    if(this.data.apiVersion() == 1) {
      // in v1, they extra scrolling slideshow images are pulled by a special json count of `SNKRSImages`, after which, they show up in the 2nd slideshow
      if(this.data.SNKRSImages > 0) this.secondSlideCount = this.data.SNKRSImages;
    }
  }

  storeSwipeImages(imgData, secondSlidesDataQueue) {
    if(this.data.apiVersion() == 1) {
      // count up to special json count of `SNKRSImages`, then put image data into 2nd array
      if(this.secondSlideCount && this.secondSlideCount < numSlides && i >= this.secondSlideCount) {
        secondSlidesDataQueue.push(imgData); // queue so we can repeat if we need to fill horizontal space
      } else {
        new VaultSwipeImage(this.data, imgData);
      }
    } else if(this.data.apiVersion() == 2) {
      new VaultSwipeImage(this.data, imgData);
    }
  }

  storeSecondaryScrollImages(secondSlidesDataQueue) {
    if(this.extraSlides) {
      // easier separation of extra slideshow in v2 data
      this.extraSlides.forEach((imgData, i) => {
        secondSlidesDataQueue.push(imgData);
      });
    }
  }

  buildImages() {
    let numSlides = this.slides.length;
    this.MODE_SCROLL_UP = 0;
    this.MODE_GRID_FADE = 1;
    this.MODE_LARGE_SWIPE = 2;
    this.mode = (numSlides > 5) ? this.MODE_GRID_FADE : this.MODE_SCROLL_UP;
    this.checkSwipeMode();
    // build images based on mode
    let secondSlidesDataQueue = [];
    this.slides.forEach((el, i) => {
      if(el.subType == "image") {
        if(this.mode == this.MODE_SCROLL_UP) {
          new VaultScrollImage(this.data, el);
        } else if(this.mode == this.MODE_GRID_FADE) {
          new VaultSquareImage(this.data, el);
        } else if(this.mode == this.MODE_LARGE_SWIPE) {
          this.storeSwipeImages(el, secondSlidesDataQueue);
        }
      }
    });
    if(this.extraSlides) this.storeSecondaryScrollImages(secondSlidesDataQueue); // for api v2 only

    // build 2nd scrolling carousel images from queue. fill up horizontal space by repeating images
    var slidesBuilt = 0;
    while(Constants.VAULT_CAROUSEL_SLIDES.length < 6 && slidesBuilt < 100) {  // make at least 6, but don't get stuck in a loop
      secondSlidesDataQueue.forEach((el, i) => {
        new VaultCarouselImage(this.data, el);
      });
      slidesBuilt++;
    }
  }

  ACTIVE_MODE(isActive) {
    if(isActive) {
      this.show();
    } else {
      // this.hide();
      window.clearTimeout(this.swipeInit);
      window.clearInterval(this.swipeInterval);
      window.clearInterval(this.reGridInterval);
    }
  }

  show() {
    // reset/empty array of placed images for collision detection
    Constants.VAULT_IMAGES_PLACED.splice(0, Constants.VAULT_IMAGES_PLACED.length);
    // create consistent max sizes to choose from for 5 or less images
    Constants.VAULT_IMAGES_CELL_SIZES = [6,8,10,7,9];
    Constants.VAULT_IMAGES_CELL_SIZES.sort(() => {return 0.5 - Math.random()});   // shuffle sizes

    // if swipe mode, start a timer
    if(this.mode == this.MODE_LARGE_SWIPE) {
      _store.set(Constants.SWIPE_ANIMATION_STEP, -1);
      this.swipeInit = setTimeout(() => {
        this.runSwipeTimer();
        this.swipeInterval = setInterval(() => this.runSwipeTimer(), 3500);
      }, 500);
    }

    // if square mode, start a timer
    if(this.mode == this.MODE_GRID_FADE) {
      this.reGridInterval = setInterval(() => this.runReGridTimer(), 7000);
    }
  }

  runSwipeTimer() {
    // move to next index
    var nextSlideIndex = _store.get(Constants.SWIPE_ANIMATION_STEP) + 1;
    if(nextSlideIndex >= Constants.VAULT_IMAGES_PLACED.length) nextSlideIndex = 0;
    _store.set(Constants.SWIPE_ANIMATION_STEP, nextSlideIndex);

    // shut down swipe animation when complete. hide() will clear the interval
    // if(_store.get(Constants.SWIPE_ANIMATION_STEP) == Constants.VAULT_IMAGES_PLACED.length + 2) {
    //   app.setActive(false);
    // }
  }

  runReGridTimer() {
    Constants.VAULT_RANDOM_LARGE_INDEX = this.randRange(0, Constants.VAULT_IMAGES_PLACED.length - 1); // pick different image indexes to be larger in the grid
    Constants.VAULT_IMAGES_PLACED.splice(0, Constants.VAULT_IMAGES_PLACED.length);  // clear array for placing & delaying images as they show
    _store.set(Constants.GLITCH_SHOW_HIDE, true);
  }

  randRange(min, max) {
    return Math.round( Math.random() * ( max - min ) ) + min;
  }

}

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

class VaultImageBase {

  constructor(data, slideData) {
    this.data = data;
    this.slideData = slideData;
    this.gridSize = (this.data.isPortrait()) ? Constants.VAULT_GRID.portrait : Constants.VAULT_GRID.landscape;
    this.positions = (this.data.isPortrait()) ? Constants.VAULT_GRID_POSITIONS.portrait : Constants.VAULT_GRID_POSITIONS.landscape;
    this.maxAttempts = 100;

    // event listeners
    _store.addListener(this, Constants.ANIMATION_FRAME);
    _store.addListener(this, Constants.ACTIVE_MODE);

    // init container
    this.stage = _store.get(Constants.PIXI_STAGE);
    this.container = new PIXI.Container();
    _store.get(Constants.APP_CONTAINER).addChild(this.container);

    // setup animation
    this.buildAnimProps();
    this.loadMedia();
  }

  buildAnimProps() {
    this.stageScale = (this.data.isPortrait()) ? this.stage.width() / 1080 : this.stage.width() / 1920;
    this.speed = 1;
    this.imageShowEase = new LinearFloat(0, 0.025);
  }

  ////////////////////
  // LOAD MEDIA
  ////////////////////

  loadMedia() {
    this.loaded = false;
    this.sprite = new PIXI.Sprite.from(this.slideData.assetUrl);
    this.sprite.anchor.set(0, 0);
    this.container.addChild(this.sprite);
  }

  ////////////////////
  // MEDIA LOADED CHECK
  ////////////////////

  isMediaLoaded() {
    return this.sprite.texture.baseTexture.valid;
  }

  checkLoaded() {
    if (this.loaded) return;
    if (this.isMediaLoaded()) {
      this.loaded = true;
      this.imageLoaded();
      // this.setInitialPosition(this.sprite);
    }
  }

  imageLoaded() {
    // override if needed
  }

  ////////////////////
  // SHOW/HIDE
  ////////////////////

  ACTIVE_MODE(isActive) {
    if(isActive) {
      this.show();
    } else {
      this.hide();
    }
  }

  show() {
    // super.show();
    this.speed = 1 + Math.random() * 0.6;
    // this.speed *= 5;
    this.setInitialPosition();
    this.imageShowEase.setTarget(1);
    this.imageShowEase.setInc(0.005);
  }

  hide() {
    // super.hide();
    this.imageShowEase.setTarget(0);
  }

  ////////////////////
  // COLLISION DETECTION
  ////////////////////

  top() { return this.sprite.y; }
  bottom() { return this.sprite.y + this.sprite.height + 1; }   // add padding so there's always at least one cell between images via collision detection
  left() { return this.sprite.x; }
  right() { return this.sprite.x + this.sprite.width + 1; }

  rectsIntersect(a, b) {
    return (a.left() <= b.right() &&
            b.left() <= a.right() &&
            a.top() <= b.bottom() &&
            b.top() <= a.bottom());
  }

  isOverlapping() {
    // check placed images for overlapping
    var overlapping = false;
    Constants.VAULT_IMAGES_PLACED.forEach((el, i) => {
      if(this != el) {
        if(this.rectsIntersect(this, el)) overlapping = true;
      }
    });
    return overlapping;
  }

}

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

class VaultScrollImage extends VaultImageBase {

  constructor(data, slideData) {
    super(data, slideData);
  }

  pickRandomGridPosition(attempts, screenStartY = 0) {
    // and random width fit to grid
    if(screenStartY == 0) this.colsWidth = 5 + Math.floor(Math.random() * 4);

    // select a random grid position (subtract width if it hangs off right edge)
    let randomCol = Math.floor(Math.random() * (this.gridSize.cols - this.colsWidth));   // keeps columns on-screen by subtracting image col width from max cols
    let maxRows = (attempts < 50) ? this.gridSize.rows * 1.3 : this.gridSize.rows * 2;
    let randomRow = (screenStartY * this.gridSize.rows) + Math.floor(Math.random() * maxRows); // allow images to go off bottom of screen. if we get more attempts, push it further down
    let x = (this.gridSize.startX + this.gridSize.cellSize * randomCol) * this.stageScale;
    let y = (this.gridSize.startY + this.gridSize.cellSize * randomRow) * this.stageScale;

    // set random props on sprite for collision detection
    this.sprite.x = x;
    this.sprite.y = y;
    if(screenStartY == 0) {
      let width = (this.gridSize.cellSize * Constants.VAULT_IMAGES_CELL_SIZES[0]) * this.stageScale;
      PixiSpriteScale.scaleToWidth(this.sprite, width);
    }
  }

  setInitialPosition() {
    // pick a random position that's not colliding
    this.pickRandomGridPosition(0);
    var attempts = 0;
    while(this.isOverlapping() && attempts < this.maxAttempts) {
      this.pickRandomGridPosition(attempts);
      attempts++;
    }

    // move to next width
    Constants.VAULT_IMAGES_CELL_SIZES.shift();

    // move along to next image
    Constants.VAULT_IMAGES_PLACED.push(this);
  }


  ANIMATION_FRAME(frame) {
    this.checkLoaded();
    this.imageShowEase.setInc(0.025);
    this.imageShowEase.update();
    // let easedShow = Penner.easeInOutExpo(this.imageShowEase.value(), 0, 1, 1);
    this.sprite.alpha = this.imageShowEase.value();

    // move!
    this.sprite.y -= this.speed * this.stageScale;
    // recycle off-screen
    if(this.sprite.y + this.sprite.height < -10) {
      // recycle at bottom of screen
      this.sprite.y = this.stage.height() + 10 + Math.random() * 20 * this.stageScale;
      // move if overlapping
      var attempts = 0;
      while(this.isOverlapping() && attempts < this.maxAttempts) {
        this.pickRandomGridPosition(attempts, 1);
        attempts++;
      }
    }
  }
}

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

class VaultSquareImage extends VaultImageBase {

  constructor(data, slideData) {
    super(data, slideData);
    // how many cells to fill?
    this.cellModSize = 4;
    this.numColSlots = Math.floor(this.gridSize.cols / this.cellModSize);
    this.numRowSlots = Math.floor(this.gridSize.rows / this.cellModSize);
    this.fadeDelay = 0;

    _store.addListener(this, Constants.GLITCH_SHOW_HIDE);
  }

  loadMedia() {
    this.loaded = false;
    this.texture = PIXI.Texture.from(this.slideData.assetUrl);
    // this.sprite = new PIXI.Sprite.from(this.slideData.assetUrl);
    this.sprite = new PIXI.TilingSprite(
        this.texture,
        100,
        100
    );
    this.sprite.anchor.set(0, 0);
    this.container.addChild(this.sprite);
  }

  randRange(min, max) {
    return Math.round( Math.random() * ( max - min ) ) + min;
  }

  pickRandomGridPosition() {
    // if(Math.random() < 0.2) this.cellModSize *=2;
    // some images get larger width
    var curCellSize = this.cellModSize;
    var curWidth = curCellSize;
    if(Constants.VAULT_IMAGES_PLACED.length % 7 == Constants.VAULT_RANDOM_LARGE_INDEX) {
      curWidth *=2;
      this.larger = true;
    } else {
      this.larger = false;
    }

    // fit to columns - pick random 4x4 grid position
    let randRow = this.randRange(0, this.positions.length - 1);
    let randCol = this.randRange(0, this.positions[randRow].length - 1);
    // pull out of preset array
    this.rowIndex = randRow;
    this.colIndex = this.positions[randRow][randCol];
    // let this.colIndex = Math.floor(Math.random() * (this.numColSlots - 2));
    // let this.rowIndex = Math.floor(1 + Math.random() * (this.numRowSlots - 2));

    // position sprite on random grid selection
    let x = (this.gridSize.startX + this.gridSize.cellSize * this.colIndex * curCellSize) * this.stageScale;
    let y = (this.gridSize.startY + this.gridSize.cellSize * this.rowIndex * curCellSize) * this.stageScale;
    let width = (this.gridSize.cellSize * curWidth) * this.stageScale;
    width -= this.gridSize.cellSize * this.stageScale * 0.5; // shrink by half a  cell


    // set randomized props
    this.sprite.x = x;
    this.sprite.y = y;
    this.sprite.width = width;
    this.sprite.height = width;

    // set tiling props
    let tileScaleX = this.sprite.width / this.texture.baseTexture.width;
    let tileScaleY = this.sprite.height / this.texture.baseTexture.height;
    let cropFillScale = (tileScaleX < tileScaleY) ? tileScaleY : tileScaleX;
    this.sprite.tileScale.x = cropFillScale;
    this.sprite.tileScale.y = cropFillScale;

    // center content by shifting half of content size that's cropped off
    let extraX = (this.texture.baseTexture.width * cropFillScale) - this.sprite.width;
    let extraY = (this.texture.baseTexture.height * cropFillScale) - this.sprite.height;
    this.sprite.tilePosition.x = -extraX / 2;
    this.sprite.tilePosition.y = -extraY / 2;
  }

  rowAndColumnAvailable(row, col) {
    if(row >= this.positions.length) return false;  // if checking beyond the grid rows for larger image, it doesn't fit!
    // see if the row/col is available
    let indexOfCol = this.positions[row].indexOf(col);
    return indexOfCol != -1;
  }

  largerImageDoesntFit() {
    // make sure larger images don't go into areas that aren't allowed
    if(!this.larger) return false;
    var overlappingOtherElements = false;
    if(this.rowAndColumnAvailable(this.rowIndex, this.colIndex + 1) == false) return true;
    if(this.rowAndColumnAvailable(this.rowIndex + 1, this.colIndex + 1) == false) return true;
    if(this.rowAndColumnAvailable(this.rowIndex + 1, this.colIndex) == false) return true;
    return false;
  }

  setInitialPosition() {
    // pick a random position that's not colliding
    this.pickRandomGridPosition();
    var attempts = 0;
    while((this.isOverlapping() || this.largerImageDoesntFit()) && attempts < this.maxAttempts) {
      this.pickRandomGridPosition();
      attempts++;
    }
    if(attempts == this.maxAttempts) {
      // console.log('Couldnt place image - hiding it offscreen');
      this.sprite.x = 9999;
      this.sprite.y = 9999;
    }

    // move along to next image
    this.fadeDelay = Constants.VAULT_IMAGES_PLACED.length;
    Constants.VAULT_IMAGES_PLACED.push(this);
  }

  ANIMATION_FRAME(frame) {
    this.checkLoaded();
    this.imageShowEase.update();
    let easedShow = Penner.easeInOutExpo(this.imageShowEase.value(), 0, 1, 1);
    this.sprite.alpha = easedShow;
  }

  show() {
    super.show();
    this.imageShowEase.setInc(0.005);
    this.imageShowEase.setValue(0);
    this.imageShowEase.setTarget(0);
    window.clearTimeout(this.glitchRelaunchTimeout);
    this.timeout = setTimeout(() => {
      this.imageShowEase.setTarget(1);
    }, this.fadeDelay * 200); // stagger delay
  }

  hide() {
    super.hide();
    window.clearTimeout(this.timeout);
    window.clearTimeout(this.glitchRelaunchTimeout);
    this.imageShowEase.setInc(0.025);
    this.imageShowEase.setTarget(0);
  }

  GLITCH_SHOW_HIDE() {
    this.hide();
    this.glitchRelaunchTimeout = window.setTimeout(() => {
      this.show();
    }, Constants.GLITCH_HALFTIME);
  }

}


////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

export class VaultSwipeImage extends VaultImageBase {

  constructor(data, slideData) {
    super(data, slideData);

    this.imageHideEase = new LinearFloat(0, 0.025);
    this.imageAlphaEase = new LinearFloat(0, 0.025);

    _store.addListener(this, Constants.SWIPE_ANIMATION_STEP);
  }

  show() {
    // override base show() behavior
    // super.show();
    this.setInitialPosition();
    this.imageShowEase.setInc(0.007);
    this.imageHideEase.setInc(0.007);
    this.imageAlphaEase.setTarget(1);
    this.resetPosition();

    // keep jordan large sneaks on top of headline text
    _store.get(Constants.APP_CONTAINER).addChild(this.container);
  }

  hide() {
    // super.hide(); // override hide super call
    this.imageAlphaEase.setTarget(0);
  }

  resetPosition() {
    this.imageShowEase.setTarget(0);
    this.imageShowEase.setValue(0);
    this.imageHideEase.setTarget(0);
    this.imageHideEase.setValue(0);
  }

  setInitialPosition() {
    // lay out horizontally
    let numImagesPlaced = Constants.VAULT_IMAGES_PLACED.length;
    this.swipeIndex = numImagesPlaced; // first cycle shouldn't show a sneaker

    // calc bounding square for responsiveness & both portrait and landscape orientations
    let smallerDimension = (this.stage.width() < this.stage.height()) ? this.stage.width() : this.stage.height();
    let boundingSquareSize = smallerDimension * 0.8;

    // check w vs h, and scale to stay within boundingSquareSize
    if(this.sprite.height > this.sprite.width) {
      PixiSpriteScale.scaleToHeight(this.sprite, boundingSquareSize);
    } else {
      PixiSpriteScale.scaleToWidth(this.sprite, boundingSquareSize);
    }

    // position and scale image
    this.sprite.x = (this.stage.width() * 2);
    this.sprite.y = this.stage.height() * 0.5;
    this.sprite.anchor.set(1, 0.5); // right-align slides, but vertically-centered

    // move along to next image
    Constants.VAULT_IMAGES_PLACED.push(this);
  }

  SWIPE_ANIMATION_STEP(stepIndex) {
    if(stepIndex == this.swipeIndex) {
      // reset and show if index is current slide
      this.resetPosition();
      this.imageShowEase.setTarget(1);
    } else {
      if(this.imageShowEase.target() == 1) {
        // if showing, slide away!
        this.imageHideEase.setTarget(1);
      }
    }
  }

  ANIMATION_FRAME(frame) {
    this.checkLoaded();

    // move
    this.imageShowEase.update();
    this.imageHideEase.update();
    let easedShow = Penner.easeInOutExpo(this.imageShowEase.value(), 0, 1, 1);
    let easedHide = Penner.easeInOutExpo(this.imageHideEase.value(), 0, 1, 1);
    this.sprite.x = (this.stage.width() * 2) - (this.stage.width() * easedShow) - (this.stage.width() * easedHide * 1.4);

    // set alpha
    this.imageAlphaEase.update();
    this.sprite.alpha = this.imageAlphaEase.value();
  }
}

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

class VaultCarouselImage extends VaultImageBase {

  constructor(data, slideData) {
    super(data, slideData);

    this.imageAlphaEase = new LinearFloat(0, 0.025);
    this.speed = new LinearFloat(0, 0.005);

    // keep track of carousel images created
    this.xIndex = Constants.VAULT_CAROUSEL_SLIDES.length;
    Constants.VAULT_CAROUSEL_SLIDES.push(this);
  }

  show() {
    this.speed.setValue(0);
    this.speed.setTarget(0.5);
    // override base show() behavior
    // super.show();
    this.setInitialPosition();
    window.clearTimeout(this.fadeTimeout);
    this.fadeTimeout = setTimeout(() => {
      this.imageAlphaEase.setTarget(1);
    }, 300 + this.xIndex * 200);
  }

  hide() {
    // super.hide(); // override hide super call
    window.clearTimeout(this.fadeTimeout);
    this.fadeTimeout = setTimeout(() => {
      this.imageAlphaEase.setTarget(0);
      this.speed.setTarget(0);
    }, this.sprite.x / 2);  // fade out left-to-right
  }

  setInitialPosition() {
    // lay out horizontally
    let numImagesPlaced = Constants.VAULT_CAROUSEL_SLIDES.length;
    this.spacing = this.stage.width() * 0.028;

    // scale based on screen height
    PixiSpriteScale.scaleToHeight(this.sprite, this.stage.height() * 0.16);

    // position and scale image. get widths of all previous slides
    this.startX = 0;
    for (var i = 0; i < this.xIndex; i++) {
      this.startX += Constants.VAULT_CAROUSEL_SLIDES[i].sprite.width + this.spacing;
    }

    this.sprite.x = this.startX;
    this.sprite.y = this.stage.height() * 0.67;
    this.sprite.anchor.set(0, 0);
  }

  ANIMATION_FRAME(frame) {
    this.checkLoaded();

    // move
    this.speed.update();
    if(this.speed.value() > 0 || this.imageAlphaEase.value() > 0) {  // if moving or fading out, update position
      this.sprite.x -= this.speed.value();
    }
    // recycle
    if(this.sprite.x < 0 && this.sprite.x < -this.sprite.width * 1.2) {
      if(!this.carouselW) { // cache width
        let lastImage = Constants.VAULT_CAROUSEL_SLIDES[Constants.VAULT_CAROUSEL_SLIDES.length - 1];
        this.carouselW = lastImage.startX + lastImage.sprite.width + this.spacing;
      }
      this.sprite.x += this.carouselW;
    }

    // set alpha
    this.imageAlphaEase.update();
    this.sprite.alpha = this.imageAlphaEase.value();
  }
}
