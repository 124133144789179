import Constants from "../../../shared/constants";
import LinearFloat from "../../../vendor/hexademic/LinearFloat";
import * as PIXI from 'pixi.js';

export default class VaultTransitionGlitch {

  constructor() {
    // event listeners
    _store.addListener(this, Constants.ANIMATION_FRAME);
    _store.addListener(this, Constants.ACTIVE_MODE);
    _store.addListener(this, Constants.GLITCH_SHOW_HIDE);

    // test shader
    this.buildShader();
    this.transitionProgress = new LinearFloat(0, 0.025);
  }

  buildShader() {
    var shaderFragTint = `
      precision mediump float;

      varying vec2 vTextureCoord;
      uniform sampler2D uSampler;
      uniform float iTime;
      uniform float amp;

      void main() {
        vec4 origColor = texture2D(uSampler, vTextureCoord);
        vec4 otherColor = vec4(
          0.5 + 0.5 * sin(iTime * 3.1),
          0.5 + 0.5 * sin(iTime * 1.2),
          0.5 + 0.5 * sin(iTime * 6.12),
          1.);
        gl_FragColor = mix(origColor, otherColor, amp * 0.15);
      }
    `;
    var shaderFragGlitch_2 = `
      precision mediump float;

      varying vec2 vTextureCoord;
      uniform sampler2D uSampler;
      uniform float iTime;
      uniform float amp;

      float rand () {
          return fract(sin(iTime)*1e4);
      }
      void main() {
        vec4 origColor = texture2D(uSampler, vTextureCoord);

      	vec2 uv = vTextureCoord;

          vec2 uvR = uv;
          vec2 uvB = uv;

          uvR.x = uv.x * 1.0 - rand() * 0.02 * 0.8;
          uvB.y = uv.y * 1.0 + rand() * 0.02 * 0.8;

          //
          if(uv.y < rand() && uv.y > rand() -0.1 && sin(iTime) < 0.0) {
          	uv.x = (uv + 0.2 * rand()).x;

            if(uv.x < 0.) uv.x = 0.;
            else if(uv.x > 1.) uv.x = 1.;
          }

          //
          vec4 c;
          c.r = texture2D(uSampler, uvR).r;
          c.g = texture2D(uSampler, uv).g;
          c.b = texture2D(uSampler, uvB).b;
          c.a = 1.;
          //
          float scanline = sin( uv.y * 80.0 * rand())/30.0;
      	  c *= 1.0 - scanline;

          //vignette
          // float vegDist = length(( 0.5 , 0.5 ) - uv);
          // c *= 1.0 - vegDist * 0.6;

          gl_FragColor = mix(origColor, c, amp);
      }
    `;
    var shaderFragGlitch_1 = `
      // from: https://www.shadertoy.com/view/MtXBDs
      precision mediump float;

      varying vec2 vTextureCoord;
      uniform sampler2D uSampler;
      uniform float amp;
      uniform float iTime;

      const float AMT = 0.1; //0 - 1 glitch amount
      const float SPEED = 0.2; //0 - 1 speed

      //2D (returns 0 - 1)
      float random2d(vec2 n) {
          return fract(sin(dot(n, vec2(12.9898, 4.1414))) * 43758.5453);
      }

      float randomRange (in vec2 seed, in float min, in float max) {
      		return min + random2d(seed) * (max - min);
      }

      // return 1 if v inside 1d range
      float insideRange(float v, float bottom, float top) {
         return step(bottom, v) - step(top, v);
      }

      void main() {
          float time = floor(iTime * 60.0);
      	  vec2 uv = vTextureCoord;

          //copy orig
          vec3 origColor = texture2D(uSampler, uv).rgb;
          vec3 outCol = texture2D(uSampler, uv).rgb;

          //randomly offset slices horizontally
          float maxOffset = AMT/2.0;
          for (float i = 0.0; i < 10.0 * AMT; i += 1.0) {
              float sliceY = random2d(vec2(time , 2345.0 + float(i)));
              float sliceH = random2d(vec2(time , 9035.0 + float(i))) * 0.25;
              float hOffset = randomRange(vec2(time , 9625.0 + float(i)), -maxOffset, maxOffset);

              vec2 uvOff = uv;
              uvOff.x += hOffset;
              if(uvOff.x < 0.) uvOff.x = 0.;
              else if(uvOff.x > 1.) uvOff.x = 1.;

              if (insideRange(uv.y, sliceY, fract(sliceY+sliceH)) == 1.0 ){
              	outCol = texture2D(uSampler, uvOff).rgb;
              }
          }

          // do slight offset on one entire channel
          float maxColOffset = AMT/6.0;
          float rnd = random2d(vec2(time, 9545.0));
          vec2 colOffset = vec2(randomRange(vec2(time , 9545.0), -maxColOffset, maxColOffset),
                                randomRange(vec2(time , 7205.0), -maxColOffset, maxColOffset));
          if (rnd < 0.33){
              outCol.r = texture2D(uSampler, uv + colOffset).r;
          } else if (rnd < 0.66){
              outCol.g = texture2D(uSampler, uv + colOffset).g;
          } else {
              outCol.b = texture2D(uSampler, uv + colOffset).b;
          }

      	  gl_FragColor = vec4(mix(origColor, outCol, amp),1.0);
      }
    `;

    // create filter stack
    this.tint = new PIXI.Filter(null, shaderFragTint, {iTime: 0, amp:0});
    this.glitch_1 = new PIXI.Filter(null, shaderFragGlitch_1, {iTime: 0, amp:0});
    this.glitch_2 = new PIXI.Filter(null, shaderFragGlitch_2, {iTime: 0, amp:0});
    // and prepare to switch filters on during transitions only
    this.filterStack = [this.tint, this.glitch_2, this.glitch_1];
    this.filtersNone = [];
    this.filtersContainer = _store.get(Constants.APP_CONTAINER);
    this.filtersContainer.filters = this.filtersNone;
  }

  ////////////////////
  // SHOW/HIDE
  ////////////////////

  ACTIVE_MODE(isActive) {
    if(isActive) {
      this.show();
    } else {
      this.hide();
    }
  }

  show() {
    this.transitionProgress.setTarget(1);
    window.clearTimeout(this.autoHideTimeout);
  }

  hide() {
    this.transitionProgress.setTarget(0);
    window.clearTimeout(this.autoHideTimeout);
  }

  GLITCH_SHOW_HIDE() {
    this.show();
    this.autoHideTimeout = window.setTimeout(() => {
      this.hide();
    }, Constants.GLITCH_HALFTIME);
  }

  ////////////////////
  // ANIMATE
  ////////////////////

  ANIMATION_FRAME(frame) {
    this.transitionProgress.update();
    if(this.transitionProgress.isComplete()) {
      if(this.filtersContainer.filters != this.filtersNone) this.filtersContainer.filters = this.filtersNone;
    } else {
      if(this.filtersContainer.filters != this.filterStack) this.filtersContainer.filters = this.filterStack;
      this.tint.uniforms.iTime = frame * 0.1;
      this.tint.uniforms.amp = Math.sin(this.transitionProgress.value() * Math.PI); // up & down on sine curve
      this.glitch_2.uniforms.iTime = frame * 0.01;
      this.glitch_2.uniforms.amp = Math.sin(this.transitionProgress.value() * Math.PI); // up & down on sine curve
      this.glitch_1.uniforms.iTime = frame * 0.0004;
      this.glitch_1.uniforms.amp = Math.sin(this.transitionProgress.value() * Math.PI); // up & down on sine curve
    }
  }

}
