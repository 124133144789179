import * as PIXI from 'pixi.js';
import Constants from "../../../shared/constants";
import LinearFloat from "../../../vendor/hexademic/LinearFloat";
import Penner from "../../../vendor/hexademic/Penner";
import PixiSpriteScale from "../../../vendor/hexademic/PixiSpriteScale";

export default class VaultBackground {

  constructor(data) {
    this.animData = data;

    // event listeners
    _store.addListener(this, Constants.ANIMATION_FRAME);
    _store.addListener(this, Constants.ACTIVE_MODE);

    // init container
    this.stage = _store.get(Constants.PIXI_STAGE);
    this.container = new PIXI.Container();
    _store.get(Constants.APP_CONTAINER).addChild(this.container);

    // setup animation
    this.buildAnimProps();
    this.loadMedia(data);
  }

  buildAnimProps() {
    this.imageShowEase = new LinearFloat(0, 0.025);
  }


  // PIXI graphics helpers

  setTopLayer() {
    _store.get(Constants.APP_CONTAINER).addChild(this.container);
  }

  ////////////////////
  // LOAD MEDIA
  ////////////////////

  loadMedia() {
    this.loaded = false;
    let blackImg = (!!this.animData.assets['background']) ? this.animData.assets['background']['black'] : this.animData.assets['bgBlack']; // backwards api compatible
    this.spriteBlack = new PIXI.Sprite.from(blackImg);
    // this.spriteBlack.tint = 0xFF0000;
    this.container.addChild(this.spriteBlack);

    let whiteImg = (!!this.animData.assets['background']) ? this.animData.assets['background']['white'] : this.animData.assets['bgWhite']; // backwards api compatible
    this.spriteWhite = new PIXI.Sprite.from(whiteImg);
    // this.spriteWhite.tint = 0xFF0000;
    this.container.addChild(this.spriteWhite);
  }

  ////////////////////
  // MEDIA LOADED CHECK
  ////////////////////

  isMediaLoaded() {
    return this.spriteBlack.texture.baseTexture.valid && this.spriteWhite.texture.baseTexture.valid;
  }

  checkLoaded() {
    if (this.loaded) return;
    if (this.isMediaLoaded()) {
      this.loaded = true;
      this.setBgImageProps(this.spriteBlack);
      this.setBgImageProps(this.spriteWhite);
    }
  }

  setBgImageProps(sprite) {
    PixiSpriteScale.scaleToFillContainerMult(sprite, this.stage.width(), this.stage.height(), 1);
    sprite.x = this.stage.width() / 2;
    sprite.y = this.stage.height() / 2;
    sprite.anchor.set(0.5, 0.5);
  }

  ////////////////////
  // SHOW/HIDE
  ////////////////////

  ACTIVE_MODE(isActive) {
    if(isActive) {
      this.show();
    } else {
      this.hide();
    }
  }

  show() {
    this.imageShowEase.setTarget(1);
  }

  hide() {
    this.imageShowEase.setTarget(0);
  }

  ////////////////////
  // ANIMATE
  ////////////////////

  ANIMATION_FRAME(frame) {
    this.checkLoaded();
    this.imageShowEase.update();
    let easedShow = Penner.easeInOutExpo(this.imageShowEase.value(), 0, 1, 1);
    this.spriteWhite.alpha = easedShow;
  }

}
