import Constants from "./constants";
import { cachingWrapper, request } from "../../../api";

export default class NewDataChecker {

  constructor() {
    // check for new speedshop data after every animation loop
    _store.addListener(this, Constants.CUR_SLIDE_INDEX);
    // check for new speedshop (video only mode) data after every video loop
    _store.addListener(this, Constants.VIDEO_LOOPED);
    // check for high heat data every 5 minutes, with any user activity resetting the timer
    _store.addListener(this, Constants.VAULT_LOADED);
    _store.addListener(this, Constants.ACTIVE_MODE);
  }

  CUR_SLIDE_INDEX(slideIndex) {
    if(slideIndex === 0) {
      this.loadJson();
    }
  }

  VIDEO_LOOPED(slideIndex) {
    this.loadJson();
  }

  VAULT_LOADED(didLoad) {
    this.restartInterval();
  }

  ACTIVE_MODE(isActive) {
    this.restartInterval();
  }

  restartInterval() {
    window.clearInterval(this.interval);
    this.interval = setInterval(() => {
      this.loadJson();
    }, 60 * 1000);
    //}, 5 * 60 * 1000);
  }

  // check both JSON sources if set by initial App bootstrap

  loadJson() {
    this.checkScreenConfig();
    this.checkAnimationData();
  }

  cacheBust() {
    let randNum = Math.round(Math.random() * 999999);
    return `&v2=${randNum}`;
  }

  checkScreenConfig() {
    if(Constants.API_URL == null) return;

    if(Constants.SCREEN_CONFIG_URL) {
	  const getScreenConfigCallback = async () => await request({
		  url: Constants.SCREEN_CONFIG_URL + Constants.SYNC_PARAMS + this.cacheBust()
	  });

	  cachingWrapper({
		  requestCallback: getScreenConfigCallback,
		  storageKey: Constants.SCREEN_CONFIG_URL_STORAGE_KEY
	  })
      .then((jsonData) => {
        console.log('Screen config animation ID:', jsonData.animation_id, Constants.SCREEN_CONFIG_ANIM_ID)
        if(jsonData.animation_id !== Constants.SCREEN_CONFIG_ANIM_ID) this.reload();
      }).catch(error => {
        this.jsonLoadFailed();
      });
    }
  }

  checkAnimationData() {
    if(Constants.API_URL == null) return;
    if(Constants.ANIMATION_DATA_URL) {
      const getAnimationCallback = async () => await request({
		  url: Constants.ANIMATION_DATA_URL + Constants.SYNC_PARAMS + this.cacheBust()
	  });

	  cachingWrapper({
		  requestCallback: getAnimationCallback,
		  storageKey: Constants.ANIMATION_DATA_URL_STORAGE_KEY
	  })
	  .then((jsonData) => {
        console.log('Animation data updated_at:', jsonData.updated_at, Constants.ANIMATION_DATA_UPDATED_AT)
        if(jsonData.updated_at !== Constants.ANIMATION_DATA_UPDATED_AT) this.reload();
      }).catch(error => {
        this.jsonLoadFailed();
      });
    }
  }

  reload() {
    console.warn('Should reload because "updated_at" has changed');
    setTimeout(() => {
      document.location.reload();
    }, 3500);
  }

  jsonLoadFailed() {
    console.warn('Couldn\'t load json');
  }
}
