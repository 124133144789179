import Constants from "../../../shared/constants";
import LoadingStatus from "../../../shared/LoadingStatus";
import VideoToBlob from "../../../vendor/hexademic/VideoToBlob";

export default class SpeedShopVideoFullscreen {

  constructor(container) {
    this.el = container;
    this.buildSlides(_store.get(Constants.DATA_PARSED));
    LoadingStatus.set(LoadingStatus.SLIDES_BUILT);
  }

  buildSlides(data) {
    // don't build anything if blank
    if(data.blank || !data.slides || data.slides.length == 0) {
      _store.set(Constants.BLANK_PAGE_LOADED, true);
      return;
    }

    // adjust for ipad?
    // if (data.isPortrait() == false) Constants.TEXT_IPAD_SCALE = 1.75;

    // build slides defined in JSON
    let slides = data.slides;
    var foundVideo = false;
    slides.forEach((slideData, i, arr) => {
      if (slideData.subType == "video" && !foundVideo) {
        var videoURL = slideData.assetUrl;
        if (!slideData.isIpad) videoURL = videoURL.replace('.mp4', '.webm');  // webm for chromium
        this.loadVideo(videoURL);
        foundVideo = true;
      }
    });
    LoadingStatus.set(LoadingStatus.SLIDES_BUILT);
  }

  loadVideo(url) {
    // load video via xhr ---------------------------
    new VideoToBlob(url, (videoEl) => {
      // store video element w/blob src
      this.videoEl = videoEl;
      this.videoEl.setAttribute('autoplay', "true");
      this.videoEl.setAttribute('width', "100%");
      this.videoEl.setAttribute('height', "100%");
      this.videoEl.removeAttribute('loop');
      this.videoEl.addEventListener('ended', (e) => this.videoEnded(e));
      this.el.appendChild(this.videoEl);
      // loading done
      setTimeout(() => {
        LoadingStatus.set(LoadingStatus.COMPLETE);
      }, 2000);
    });
  }

  videoEnded(e) {
    // manually loop the video - we rely on the 'ended' event to trigger NewDataChecker requests
    _store.set(Constants.VIDEO_LOOPED, true);
    this.videoEl.play();
  }

}
