import Penner from "../../../vendor/hexademic/Penner";
import VaultTextBase from "./VaultTextBase";
import * as PIXI from 'pixi.js';
import Constants from "../../../shared/constants";

export default class VaultTextBody extends VaultTextBase {

  constructor(data) {
    super(data);
    // setup animation
    this.textWhite = this.buildTextSprite('#ffffff');
    this.textBlack = this.buildTextSprite('#000000');
  }

  ////////////////////
  // BUILD TEXT
  ////////////////////

  buildTextSprite(textColor, bold=false) {
    // set responsive width for text style
    let wordWrapWidth = (this.animData.isPortrait()) ?
      0.21 * this.stage.height() :
      0.38 * this.stage.height();
    let textSize = (this.animData.isPortrait()) ?
      0.0144 * this.stage.width() :
      0.0134 * this.stage.height();
    let lineSpacing = (this.animData.isPortrait()) ?
      textSize * 2.1 :
      textSize * 2.2;

    let fontFam = (bold) ? 'HelveticaBold' : 'Helvetica';
    let textStyle = new PIXI.TextStyle({
      fontFamily: fontFam,
      fontSize: textSize * Constants.TEXT_RESOLUTION,
      lineHeight: lineSpacing * Constants.TEXT_RESOLUTION,
      // letterSpacing: this.stage.height() * -0.003,
      fill: textColor,
      wordWrap: true,
      wordWrapWidth: wordWrapWidth * Constants.TEXT_RESOLUTION,
    });

    // build text sprite
    let sprite = new PIXI.Text(this.animData.textBodyCopy, textStyle);
    sprite.anchor.set(0, 0);
    sprite.scale.set(1 / Constants.TEXT_RESOLUTION); // scale down from retina-style 2x scaling

    // position
    if(this.animData.isPortrait()) {
      sprite.x = 0.104 * this.stage.width();
      sprite.y = 0.929 * this.stage.height();
    } else {
      sprite.x = 0.1166 * this.stage.width();
      sprite.y = 0.9196 * this.stage.height();
    }

    // rotate
    sprite.rotation = Math.PI / -2.0;

    // add to stage
    this.container.addChild(sprite);
    return sprite;
  }

  ////////////////////
  // ANIMATE
  ////////////////////

  ANIMATION_FRAME(frame) {
    super.ANIMATION_FRAME(frame);

    let easedShow = Penner.easeInOutExpo(this.activeShowEase.value(), 0, 1, 1);
    this.textWhite.alpha = 1. - easedShow;
    this.textBlack.alpha = easedShow;
  }

}
