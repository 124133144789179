import Constants from "../../../shared/constants";
import * as PIXI from 'pixi.js';
import LinearFloat from "../../../vendor/hexademic/LinearFloat";

export default class VaultTextBase {

  constructor(data) {
    this.animData = data;

    // event listeners
    _store.addListener(this, Constants.ANIMATION_FRAME);
    _store.addListener(this, Constants.ACTIVE_MODE);

    // init container
    this.stage = _store.get(Constants.PIXI_STAGE);
    this.container = new PIXI.Container();
    _store.get(Constants.APP_CONTAINER).addChild(this.container);

    // setup animation
    this.activeShowEase = new LinearFloat(0, 0.025);
  }

  ////////////////////
  // SHOW/HIDE
  ////////////////////

  ACTIVE_MODE(isActive) {
    if(isActive) {
      this.show();
    } else {
      this.hide();
    }
  }

  show() {
    this.activeShowEase.setTarget(1);
  }

  hide() {
    this.activeShowEase.setTarget(0);
  }

  ////////////////////
  // ANIMATE
  ////////////////////

  ANIMATION_FRAME(frame) {
    this.activeShowEase.update();
  }

}
