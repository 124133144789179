import DataParser from "./DataParser";
import Constants from "./constants";

export default class DataParserV2 extends DataParser {

  constructor(jsonData) {
    super(jsonData);
  }

  // override v1 methods of pulling data from json

  storeCustomData(data) {
    // custom to this data parser (v2)
    this.created_by_app_user_id = data.created_by_app_user_id;
    this.animation_type_id = data.animation_type_id;
    this.store_id = data.store_id;
  }

  storeTextData(data) {
    // speedshop text friendly property names
    if(data.json_data.text) {
      this.textHeadline = (data.json_data.text.length >= 1) ? data.json_data.text[0] : null;
      this.textSubHeadline = (data.json_data.text.length >= 2) ? data.json_data.text[1] : null;
      this.textProductName = (data.json_data.text.length >= 3) ? data.json_data.text[2] : null;
      this.textTech1BodyCopy = (data.json_data.text.length >= 4) ? data.json_data.text[3] : null;
      this.textTech2BodyCopy = (data.json_data.text.length >= 5) ? data.json_data.text[4] : null;
      this.textMoreColors = (data.json_data.text.length >= 6) ? data.json_data.text[5] : null;
    }

    // high heat text friendly property names
    this.textBigHeadline = (data.json_data.text.length >= 1) ? data.json_data.text[0] : null;
    this.textProductSmall = (data.json_data.text.length >= 2) ? data.json_data.text[1] : null;
    this.textBodyCopy = (data.json_data.text.length >= 3) ? data.json_data.text[2] : null;
    this.textQuote = (data.json_data.text.length >= 4) ? data.json_data.text[3] : null;
    this.textQuoteAttribution = (data.json_data.text.length >= 5) ? data.json_data.text[4] : null;
  }

  storeSlidesData(data) {
    if(data.json_data.slides) {
      // switch from map to reduce to skip slide data objects that aren't populated
      let category = this.category;
      let layout = this.layout;
      this.slides = data.json_data.slides.reduce(function(result, el) {
        // add new object to empty array if data looks good
        if(el.assetUrl) {
          result.push({
            primaryColor: el.primaryColor,
            subType: el.subType,
            assetId: el.assetId,
            assetUrl: el.assetUrl,
            isIpad: layout.toLowerCase() === Constants.LAYOUT_FOOTWEAR,
            duration: el.duration,
            transition: el.transition,
          });
        }
        // pass the array forward without adding to it
        return result;
      }, []);
      if(data.json_data.extras) {
        this.extras = data.json_data.extras.reduce(function(result, el) {
          // add new object to empty array if data looks good
          if(el.assetUrl) {
            result.push({
              primaryColor: el.primaryColor,
              subType: el.subType,
              assetId: el.assetId,
              assetUrl: el.assetUrl,
              isIpad: layout.toLowerCase() === Constants.LAYOUT_FOOTWEAR,
              duration: el.duration,
              transition: el.transition,
            });
          }
          // pass the array forward without adding to it
          return result;
        }, []);
      }

      // remove 3rd slide for testing a single tech info
      // this.slides = [];
      // this.slides.splice(0,2);
    }
  }

  transformData(data) {
    // empty override until further notice
  }

  containerClass() {
    // some `animation_type_id`s are enough to determine container size. otherwise, we use the `layout` as well
    let compoundIdAndLayout = Constants.ANIM_ID_TO_CSS_CLASS["anim_"+this.animation_type_id+"_"+this.layout];
    if(!!compoundIdAndLayout) {
      return compoundIdAndLayout;
    } else {
      return Constants.ANIM_ID_TO_CSS_CLASS["anim_"+this.animation_type_id];
    }
  }

  isPortrait() {
    return  (this.animation_type_id == Constants.ANIM_ID_VIDEO && this.layout == Constants.LAYOUT_APPAREL) ||
            (this.animation_type_id == Constants.ANIM_ID_SLIDESHOW && this.layout == Constants.LAYOUT_APPAREL) ||
            (this.animation_type_id == Constants.ANIM_ID_SPEED_SHOP && this.layout == Constants.LAYOUT_APPAREL) ||
            this.animation_type_id == Constants.ANIM_ID_VISIBLE_HEAT;
  }

  isSpeedshop() {
    return (this.animation_type_id == Constants.ANIM_ID_SPEED_SHOP || this.animation_type_id == Constants.ANIM_ID_VIDEO || this.animation_type_id == Constants.ANIM_ID_SLIDESHOW);
  }

  isHOI() {
    return (this.animation_type_id == Constants.ANIM_ID_PEDESTAL || this.animation_type_id == Constants.ANIM_ID_VISIBLE_HEAT);
  }

  isSlideshow() {
    return (this.animation_type_id == Constants.ANIM_ID_SLIDESHOW || this.slideshowOnly);
  }

  isVideo() {
    return (this.animation_type_id == Constants.ANIM_ID_VIDEO || this.videoOnly);
  }

  apiVersion() {
    return 2;
  }
}
