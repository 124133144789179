import PixiSpriteScale from "../../../vendor/hexademic/PixiSpriteScale";
import SlideBase from "./SlideBase";

export default class SlidePreviewImage extends SlideBase {

  constructor(slideData) {
    super(null);
    this.slideData = slideData;
    this.slideIndex = _store.get(Constants.NUM_SLIDES) - 2; // real slide is create first, and we want to show one slide in advance, so index is -2
    this.slideTime = Constants.SLIDE_TIME_IMAGE;
    this.buildAnimProps();
    this.buildMask();
    this.loadMedia();
  }

  ////////////////////
  // INIT MASK & ANIM PROPS
  ////////////////////

  buildMask() {
    this.maskGraphics = new PIXI.Graphics();
    this.maskGraphics.beginFill(0xFF700B, 1);
    this.maskW = (this.slideData.isIpad) ? this.stage.width() * 0.229 : this.stage.width() * 0.401;
    this.maskH = (this.slideData.isIpad) ? this.stage.height() * 0.229 : this.stage.height() * 0.286;
    this.maskGraphics.drawRect(0, 0, -this.maskW, this.maskH);

    this.maskX = (this.slideData.isIpad) ? this.stage.width() * 0.71 : this.stage.width() * 0.528;
    this.maskY = (this.slideData.isIpad) ? this.stage.height() * 0.694 : this.stage.height() * 0.618;
    this.maskGraphics.position.set(this.maskX + this.maskW, this.maskY);
  }

  buildAnimProps() {
    this.maskShowEase = new LinearFloat(1, 0.02);
    this.maskHideEase = new LinearFloat(1, 0.03);
    this.imageShowEase = new LinearFloat(1, 0.015);
    this.imageHideEase = new LinearFloat(0, 0.015);
  }

  setTopLayer() {
    // override to use the PIP_CONTAINER
    _store.get(Constants.PIP_CONTAINER).addChild(this.container);
  }

  ////////////////////
  // LOAD MEDIA
  ////////////////////

  loadMedia() {
    this.loaded = false;
    this.sprite = new PIXI.Sprite.from(this.slideData.assetUrl);
    this.container.addChild(this.sprite);
    this.container.addChild(this.maskGraphics);
    // _store.get(Constants.PIP_CONTAINER).addChild(this.container);
  }

  ////////////////////
  // MEDIA LOADED CHECK
  ////////////////////

  isMediaLoaded() {
    return this.slideData.subType == "image" && this.sprite.texture.baseTexture.valid;
  }

  checkLoaded() {
    if (this.loaded) return;
    if (this.isMediaLoaded()) {
      this.loaded = true;
      PixiSpriteScale.scaleToFillContainerMult(this.sprite, this.maskGraphics.width, this.maskGraphics.height, Constants.IMAGE_SLIDE_SCALE);
      this.spriteX = this.maskX + this.maskGraphics.width / 2;
      this.spriteY = this.maskY + this.maskGraphics.height / 2;
      this.sprite.position.set(this.spriteX, this.spriteY);
      this.sprite.mask = this.maskGraphics;
      this.sprite.anchor.set(0.5, 0.5);
    }
  }

  ////////////////////
  // SHOW/HIDE
  ////////////////////

  CUR_SLIDE_INDEX(newSlideIndex) {
    super.CUR_SLIDE_INDEX(newSlideIndex);
  }

  show() {
    if(this.curSlide().isPrimaryColor() == true) return; // (don't draw this slide)
    super.show(false);
    this.maskShowEase.setValue(0);
    this.maskShowEase.setTarget(1);
    this.maskHideEase.setValue(0);
    this.maskHideEase.setTarget(0);
    this.imageShowEase.setValue(0);
    // setTimeout(() => {
      this.imageShowEase.setTarget(1);
    // }, 100);
    this.imageHideEase.setValue(0);
    this.imageHideEase.setTarget(0);
  }

  hide() {
    super.hide();
    this.maskHideEase.setTarget(1);
    // setTimeout(() => {
      this.imageHideEase.setTarget(1);
    // }, 300);
  }

  ////////////////////
  // ANIMATE
  ////////////////////

  ANIMATION_FRAME(frame) {
    this.checkLoaded();
    this.animateImageAndMask();
  }

  animateImageAndMask() {
    // lerp values
    this.maskShowEase.update();
    this.maskHideEase.update();
    this.imageShowEase.update();
    this.imageHideEase.update();

    // move image & mask layers
    let axis = 'x';

    // let maskPos = this.maskX + this.maskGraphics.width * 2 +
    //               this.maskShowEase.valuePenner(Penner.easeOutQuad) * this.maskGraphics.width * -2;
                  // this.maskHideEase.valuePenner(Penner.easeInOutExpo) * this.maskGraphics.width * -2;
    this.maskGraphics.width = this.maskShowEase.valuePenner(Penner.easeOutQuad) * this.maskW;

    let imagePos = this.spriteX + this.maskW +
                  this.imageShowEase.valuePenner(Penner.easeOutExpo) * this.maskW * -1 +
                  this.imageHideEase.valuePenner(Penner.easeInExpo) * this.maskW * -1.5;
    this.sprite[axis] = imagePos;

    // let maskPos = this.maskShowEase.valuePenner(Penner.easeInOutExpo) * dimension;

    // let imagePos = this.imageShowEase.valuePenner(Penner.easeInExpo) * dimension * 0.25;
    // if (this.nextSlide().isPrimaryColor() == this.slideData.isIpad) {
    //   // if next slide moves in the same direction, slide this one out when hiding
    //   imagePos -= this.imageHideEase.valuePenner(Penner.easeInOutQuad) * dimension * 1.5;
    // }
    // if (this.sprite) this.sprite[axis] = dimension / 2 + imagePos;
  }
}
