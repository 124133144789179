class Constants {
	constructor() {
	}
}

// api
// 'https://hyperlive-test-api.nikecms.com/';
// 'https://flagship-api.nikecms.com/'

const getApiUrl = () => {
	var r = {};
	var regexParse = new RegExp('([a-z-0-9]{2,63}).([a-z.]{2,5})$');
	var p = regexParse.exec(window.location.hostname);
	if (window.location.hostname === 'localhost' || p === null) {
		return 'https://flagship-api.nikecms.com/';
	}
	r.domain = p[1];
	r.type = p[2];
	r.subdomain = window.location.hostname.replace(r.domain + '.' + r.type, '').slice(0, -1);
	r.subdomain = r.subdomain.replace('-displays', '');
	return `https://${r.subdomain}-api.${r.domain}.${r.type}/`;
}

Constants.API_URL = null;
Constants.VERSION = '1.1.2';
Constants.SYNC_PARAMS = '&sync=true&version=' + Constants.VERSION;
// filled in with first requests
Constants.SCREEN_CONFIG_URL = null;
Constants.SCREEN_CONFIG_ANIM_ID = null;
Constants.ANIMATION_DATA_URL = null;
Constants.ANIMATION_DATA_UPDATED_AT = null;
Constants.DEV_MODE = false;

// elements
Constants.PIXI_STAGE = 'PIXI_STAGE';
Constants.APP_CONTAINER = 'APP_CONTAINER';
Constants.MODAL_CONTAINER = 'MODAL_CONTAINER';
Constants.PIP_CONTAINER = 'PIP_CONTAINER';

// template params
// v1 data `category` values
Constants.CATEGORY_TV = "tv";
Constants.CATEGORY_IPAD = "ipad";
Constants.CATEGORY_PEDESTAL = "pedestal";
Constants.CATEGORY_HIGH_HEAT = "highheat";
// v2 data `layout` values
Constants.LAYOUT_FOOTWEAR = "footwear";
Constants.LAYOUT_APPAREL = "apparel";
// v2 animation ids
Constants.ANIM_ID_PEDESTAL = 1;
Constants.ANIM_ID_SLIDESHOW = 2;
Constants.ANIM_ID_SPEED_SHOP = 3;
Constants.ANIM_ID_VIDEO = 4;
Constants.ANIM_ID_VISIBLE_HEAT = 5;
// data to container class
Constants.CATEGORY_TO_CSS_CLASS = {
	"tv": "tv-portrait",
	"ipad": "ipad",
	"pedestal": "tv-landscape",
	"highheat": "tv-portrait",
	"square-test": "square-test",
};
Constants.ANIM_ID_TO_CSS_CLASS = {};
Constants.ANIM_ID_TO_CSS_CLASS["anim_" + Constants.ANIM_ID_PEDESTAL] = "tv-landscape";
Constants.ANIM_ID_TO_CSS_CLASS["anim_" + Constants.ANIM_ID_SLIDESHOW + "_" + Constants.LAYOUT_FOOTWEAR] = "ipad";
Constants.ANIM_ID_TO_CSS_CLASS["anim_" + Constants.ANIM_ID_SLIDESHOW + "_" + Constants.LAYOUT_APPAREL] = "tv-portrait";
Constants.ANIM_ID_TO_CSS_CLASS["anim_" + Constants.ANIM_ID_SPEED_SHOP + "_" + Constants.LAYOUT_FOOTWEAR] = "ipad";
Constants.ANIM_ID_TO_CSS_CLASS["anim_" + Constants.ANIM_ID_SPEED_SHOP + "_" + Constants.LAYOUT_APPAREL] = "tv-portrait";
Constants.ANIM_ID_TO_CSS_CLASS["anim_" + Constants.ANIM_ID_VIDEO + "_" + Constants.LAYOUT_FOOTWEAR] = "ipad";
Constants.ANIM_ID_TO_CSS_CLASS["anim_" + Constants.ANIM_ID_VIDEO + "_" + Constants.LAYOUT_APPAREL] = "tv-portrait";
Constants.ANIM_ID_TO_CSS_CLASS["anim_" + Constants.ANIM_ID_VISIBLE_HEAT] = "tv-portrait";
Constants.ANIM_ID_TO_CSS_CLASS["anim_square"] = "square-test";


// browser
Constants.isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
Constants.RESIZE = 'RESIZE';
Constants.FRAME_COUNT = 'FRAME_COUNT';
Constants.ANIMATION_FRAME = 'ANIMATION_FRAME';
Constants.FPS = 'FPS';
Constants.LOADING = 'LOADING';
Constants.BLANK_PAGE_LOADED = 'BLANK_PAGE_LOADED';
Constants.DATA_PARSED = 'DATA_PARSED';

// slides
Constants.CUR_SLIDE = 'CUR_SLIDE';
Constants.SLIDES_ARRAY = 'SLIDES_ARRAY';
Constants.NUM_SLIDES = 'NUM_SLIDES';
Constants.SLIDES_LOADED = 'SLIDES_LOADED';
Constants.CUR_SLIDE_INDEX = 'CUR_SLIDE_INDEX';
Constants.VIDEO_LOOPED = 'VIDEO_LOOPED';

// slideshow config
Constants.IMAGE_SLIDE_SCALE = 1.1;
Constants.SLIDE_TIME_IMAGE = 3000;
Constants.SLIDE_TIME_TITLE = 5000;
Constants.MODAL_SHOW_DELAY = 1000;
Constants.MODAL_SHOW_DELAY_STAGE_2 = 300;
Constants.TEXT_RESOLUTION = 2;
Constants.TEXT_IPAD_SCALE = 0.9; // this gets adjusted in DataLoader is we're an ipad layout
Constants.TEXT_FILL_COLOR = '#000000';
Constants.TECH_OFFSET = 'TECH_OFFSET';
Constants.SLIDE_ANIM_SLIDE = "slide";
Constants.SLIDE_ANIM_CROSSFADE = "crossfade";
Constants.SLIDE_ANIM_HARD_CUT = "hard_cut";

// Vault
Constants.VAULT_LOADED = 'VAULT_LOADED';
Constants.ACTIVE_MODE = 'ACTIVE_MODE';
Constants.VAULT_QUOTE_TEXT_SPRITE = 'VAULT_QUOTE_TEXT_SPRITE';
Constants.SWIPE_ANIMATION_STEP = 'SWIPE_ANIMATION_STEP';
// Constants.VAULT_GRID_LAYOUT_STEP = [0, 0];
Constants.GLITCH_SHOW_HIDE = 'GLITCH_SHOW_HIDE';
Constants.GLITCH_HALFTIME = 300;
Constants.VAULT_RANDOM_LARGE_INDEX = 0;
Constants.VAULT_IMAGES_PLACED = [];
Constants.VAULT_CAROUSEL_SLIDES = [];
Constants.VAULT_IMAGES_CELL_SIZES = [];
Constants.VAULT_GRID = {
	landscape: {
		cellSize: 48.75,
		cols: 35,
		rows: 18,
		startX: 189,
		startY: 164,
	},
	portrait: {
		cellSize: 48.75,
		cols: 19,
		rows: 36,
		startX: 30,
		startY: 142,
	}
};

Constants.VAULT_GRID_POSITIONS = {
	landscape: [
		[6, 7],
		[0, 1, 2, 3, 4, 5, 6, 7, 8],
		[3, 4, 5, 6, 7, 8],
		[3, 4, 5, 6, 7, 8],
		[4, 5, 6, 7, 8],
	],
	portrait: [
		[0, 2, 3, 4],
		[0, 1, 2],
		[0, 1],
		[0, 1],
		[0, 1, 2, 3, 4],
		[0, 1, 2, 3, 4],
		[0, 1, 2, 3, 4],
		[4],
		[4],
	]
};

export default Constants;
