import VaultTextBase from "./VaultTextBase";
import * as PIXI from 'pixi.js';
import Constants from "../../../shared/constants";
import Penner from "../../../vendor/hexademic/Penner";

export default class VaultTextProduct extends VaultTextBase {

  constructor(data) {
    super(data);
    // setup animation
    this.textWhite = this.buildTextSprite('#ffffff',true);
    this.textBlack = this.buildTextSprite('#000000',true);
  }

  ////////////////////
  // BUILD TEXT
  ////////////////////

  buildTextSprite(textColor, bold=false) {
    // set responsive width for text style
    let wordWrapWidth = (this.animData.isPortrait()) ?
      (360/1080) * this.stage.width() :
      (1400/1920) * this.stage.width();
    let textSize = (this.animData.isPortrait()) ?
      (28.5/1920) * this.stage.height() :
      (30/1080) * this.stage.height();
    let lineSpacing = (this.animData.isPortrait()) ?
      textSize * 1.45 :
      textSize * 1.65;
    let align = (this.animData.isPortrait()) ?
      'left' : // This might change, leaving in conditional for now
      'left';

    let fontFam = (bold) ? 'HelveticaBold' : 'NeueHelveticaHOI';
    let textStyle = new PIXI.TextStyle({
      fontFamily: fontFam,
      fontSize: textSize * Constants.TEXT_RESOLUTION,
      lineHeight: lineSpacing * Constants.TEXT_RESOLUTION,
      // letterSpacing: this.stage.width() * -0.005,
      fill: textColor,
      wordWrap: true,
      wordWrapWidth: wordWrapWidth * Constants.TEXT_RESOLUTION,
      align: align,
    });

    // build text sprite
    let sprite = new PIXI.Text(this.animData.textProductSmall, textStyle);
    sprite.anchor.set(0, 0);
    sprite.scale.set(1 / Constants.TEXT_RESOLUTION); // scale down from retina-style 2x scaling

    // position
    if(this.animData.isPortrait()) {
      // sprite.x = (712/1080) * this.stage.width(); // old position
      sprite.x = (730/1080) * this.stage.width();
      sprite.y = (320/1920) * this.stage.height();
    } else {
      sprite.x = (60/1920) * this.stage.width();
      sprite.y = (90/1080) * this.stage.height();
    }

    // add to stage
    this.container.addChild(sprite);
    return sprite;
  }

  ////////////////////
  // ANIMATE
  ////////////////////

  ANIMATION_FRAME(frame) {
    super.ANIMATION_FRAME(frame);

    let easedShow = Penner.easeInOutExpo(this.activeShowEase.value(), 0, 1, 1);
    this.textWhite.alpha = 1. - easedShow;
    this.textBlack.alpha = easedShow;
  }

}
