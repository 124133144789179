import VaultTextBase from "./VaultTextBase";
import Constants from "../../../shared/constants";
import * as PIXI from 'pixi.js';
import Penner from "../../../vendor/hexademic/Penner";

export default class VaultTextQuote extends VaultTextBase {

  constructor(data) {
    super(data);
    // setup animation
    this.textBlack = this.buildTextSprite('#000000');
    // store for credit text layout
    _store.set(Constants.VAULT_QUOTE_TEXT_SPRITE, this.textBlack);
  }

  ////////////////////
  // BUILD TEXT
  ////////////////////

  buildTextSprite(textColor, bold=false) {
    // set responsive width for text style
    let wordWrapWidth = (this.animData.isPortrait()) ?
      (800/1080) * this.stage.width() :
      (1400/1920) * this.stage.width();
    let textSize = (this.animData.isPortrait()) ?
      (52/1920) * this.stage.height() :
      (48/1080) * this.stage.height();
    let lineSpacing = (this.animData.isPortrait()) ?
      textSize * 1.35 :
      textSize * 1.35;

    let fontFam = (bold) ? 'HelveticaBold' : 'NeueHelveticaHOI';
    let textStyle = new PIXI.TextStyle({
      fontFamily: fontFam,
      fontSize: textSize * Constants.TEXT_RESOLUTION,
      lineHeight: lineSpacing * Constants.TEXT_RESOLUTION,
      // letterSpacing: this.stage.width() * -0.005,
      fill: textColor,
      wordWrap: true,
      wordWrapWidth: wordWrapWidth * Constants.TEXT_RESOLUTION,
    });

    // build text sprite
    let sprite = new PIXI.Text(this.animData.textQuote, textStyle);
    sprite.anchor.set(0, 0);
    sprite.scale.set(1 / Constants.TEXT_RESOLUTION); // scale down from retina-style 2x scaling

    // position
    if(this.animData.isPortrait()) {
      // sprite.x = (712/1080) * this.stage.width(); // old position
      sprite.x = (150/1080) * this.stage.width();
      sprite.y = (1610/1920) * this.stage.height();
    } else {
      sprite.x = (284/1920) * this.stage.width();
      sprite.y = (458/1080) * this.stage.height();
    }

    // add to stage
    this.container.addChild(sprite);
    return sprite;
  }

  ////////////////////
  // ANIMATE
  ////////////////////

  ANIMATION_FRAME(frame) {
    super.ANIMATION_FRAME(frame);

    let easedShow = Penner.easeInOutExpo(this.activeShowEase.value(), 0, 1, 1);
    this.textBlack.alpha = easedShow;
  }

}
